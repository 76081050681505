import React from "react";

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BASE_API_URL } from "../../constants";
import { onError } from "apollo-link-error";

import { createNetworkStatusNotifier } from "react-apollo-network-status";
import Loading from "./Loading";

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

export const GlobalLoadingIndicator = () => {
  const status = useApolloNetworkStatus();
  return (
    <Loading
      working={status.numPendingQueries > 0 || status.numPendingMutations > 0}
    />
  );
};

// const LOGIN_URL = `${BASE_API_URL}/points/users/login`;
// const LOGIN_URL = 'http://localhost:4000';
const LOGIN_URL = `${BASE_API_URL}`;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      const error = graphQLErrors[i];
      if (error.extensions && error.extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("user");
        localStorage.removeItem("loggedUser");
        localStorage.removeItem("access_token");
        localStorage.removeItem("showMenu");
        window.location.href = "/";
        break;
      }
    }
  }
});

const httpLink = createHttpLink({
  //uri: '/graphql',
  uri: LOGIN_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("access_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, link, authLink.concat(httpLink)]), //authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
