import gql from "graphql-tag";

const GET_MARKERSBYGROUP_QUERY = gql`
  query PointsByGroups($groupsIDs: [Int], $lastseconds: Int) {
    pointsByGroups(groupsIDs: $groupsIDs, lastseconds: $lastseconds) {
      evt
      odo
      eid
      id
      pat
      nMov
      nCli
      idCh
      nCh
      nInt
      lat
      lon
      vel
      cur
      fGPS
      tIgn
      tDet
      typ
      mid
      dir
      roadStatus
      activeRouteName
      asset {
        itSupportsOBD2
        typeMobile {
          id
        }
      }
    }
  }
`;

export default GET_MARKERSBYGROUP_QUERY;
