// eslint-disable react/forbid-prop-types
import { Snackbar } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddLocation from "@material-ui/icons/AddLocation";
import GpsFixed from "@material-ui/icons/GpsFixed";
import LocationOff from "@material-ui/icons/LocationOff";
import Streetview from "@material-ui/icons/Streetview";
import WifiIcon from "@material-ui/icons/Wifi";
import MuiAlert from "@material-ui/lab/Alert";
import classNames from "classnames";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";
import OBD2ModalToggleButton from "../OBD2Modal/OBD2ModalToggleButton";
import MarkerPopup from "./MarkerPopup";
import AssetIcon from "../Base/AssetIcon";
import "./NavigationPopup.scss";

const LocationOffButton = ({
  mid,
  removeAssetToMultipleTracking,
  showAlert,
  t,
}) => {
  return (
    <a
      style={{ display: "table-cell", cursor: "pointer" }}
      title={t("Quitar de seguimiento múltiple")}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        removeAssetToMultipleTracking(mid);
        showAlert(
          t("La unidad se quitó correctamente del seguimiento múltiple")
        );
      }}
    >
      <LocationOff />
    </a>
  );
};

const AddLocationButton = ({
  mid,
  addAssetToMultipleTracking,
  showAlert,
  assetsMultipleTracking,
  t,
}) => {
  return (
    <a
      style={{ display: "table-cell", cursor: "pointer" }}
      title={t("Agregar a seguimiento múltiple")}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        try {
          if (assetsMultipleTracking.length <= 3) {
            addAssetToMultipleTracking(mid);
            showAlert(
              t("La unidad se agregó correctamente para seguimiento múltiple")
            );
          } else
            showAlert(
              t(
                "Ya existen 4 unidades en el seguimiento múltiple_ Por favor_ quite alguna para poder continuar agregando_"
              )
            );
        } catch (e) {
          console.log(e);
        }
      }}
    >
      <AddLocation />
    </a>
  );
};

class NavigationPopup extends Component {
  static propTypes = {
    // variant: PropTypes.oneOf(AVAILABLE_VARIANTS),
    markerInfo: PropTypes.object,
    toggleFillMarkerInfo: PropTypes.func,
  };

  static defaultProps = {
    // variant: AVAILABLE_VARIANTS[0],
    markerInfo: {},
    toggleFillMarkerInfo: () => {},
  };

  state = {
    isOpen: false,
    showAlert: false,
    alertMessage: "",
  };

  _handleToggleExtendedContent = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  _buildExtendedRows = () => {
    const { isOpen } = this.state;
    const {
      markerInfo: {
        nCli,
        nCh,
        nMov,
        vel,
        tIgn,
        cur,
        // fGPS,
        fGPSd,
        dir,
        mid,
        pos: { lat, lng },
        typ,
        roadStatus,
        activeRouteName,
      },
      t,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const cellsClassname =
      "marker-popup__row__item marker-popup__content-text pad-vert-2 pad-hor-4";

    const getState = (state) => {
      switch (state) {
        case 1:
          return { color: "#BFE2A7", status: t("On Time") };
        case 2:
          return { color: "#70BDE8", status: t("Early") };

        case 3:
          return { color: "#FFFFA0", status: t("Late") };

        case 4:
          return { color: "#DA6F6F", status: t("Off Route") };
        case 5:
          return { color: "#FFA382", status: t("Skipped Stops") };

        default:
          return { color: "white", status: t("Unknown") };
      }
    };

    const { color, status } = getState(roadStatus);
    // console.log("props", this.props);
    // console.log("roadStatus", roadStatus);
    // console.log("activeRouteName", activeRouteName);
    return (
      <div className="marker-popup__content--extended">
        <div className="marker-popup__row marker-popup__row--dark">
          <div
            title={nCli}
            className="marker-popup__content-text marker-popup__content-text__title marker-popup_title_overflow pad-vert-2 pad-hor-3"
          >
            {nCli}
            {/* {!this.props.viewDetail && (
              <input type="checkbox" onChange={this.props.setTrace}></input>
            )} */}
          </div>
          {roadStatus && typ !== "inactive" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingTop: "1px",
                color: "black",
                background: color,
                fontWeight: "700",
                borderRadius: "2px",
                width: "100%",
                height: "80%",
                marginLeft: "5px",
                marginTop: "6px",
              }}
              title={`${t("Ruta Activa")}: ${activeRouteName}`}
            >
              {status}
            </div>
          )}
          {this.props.viewDetail ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  paddingRight: "10px",
                  width: "100%",
                }}
              >
                <a
                  style={{ display: "table-cell", cursor: "pointer" }}
                  href={`/tracking/${mid}`}
                  target="_blank"
                  title={t("Seguir en nueva ventana")}
                >
                  <GpsFixed too="true" />
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                {this.props.assetsMultipleTracking.indexOf(mid) !== -1 ? (
                  <LocationOffButton
                    mid={mid}
                    removeAssetToMultipleTracking={
                      this.props.removeAssetFromMultipleTracking
                    }
                    showAlert={(message) =>
                      this.setState({ showAlert: true, alertMessage: message })
                    }
                    t={t}
                  />
                ) : (
                  <AddLocationButton
                    mid={mid}
                    addAssetToMultipleTracking={
                      this.props.addAssetToMultipleTracking
                    }
                    assetsMultipleTracking={this.props.assetsMultipleTracking}
                    showAlert={(message) =>
                      this.setState({ showAlert: true, alertMessage: message })
                    }
                    t={t}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  paddingRight: "10px",
                  width: "100%",
                }}
              >
                {this.props.assetsMultipleTracking.indexOf(mid) !== -1 ? (
                  <LocationOffButton
                    mid={mid}
                    removeAssetToMultipleTracking={
                      this.props.removeAssetFromMultipleTracking
                    }
                    showAlert={(message) =>
                      this.setState({ showAlert: true, alertMessage: message })
                    }
                    t={t}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>

        <div className="marker-popup__row">
          <div className={cellsClassname}>
            <AccountCircleIcon className="marker-popup__driver-icon" />

            <div className="marker-popup__content-text__title">{nCh}</div>
          </div>
          <div className={cellsClassname}>
            <div className="marker-popup__content-text__title">{nMov}</div>
          </div>
        </div>

        <div className="marker-popup__row">
          <div className="marker-popup__row__item">
            <div className="generic-popup-veinte marker-popup__row__item marker-popup__content-icon">
              <WifiIcon />
            </div>
            <div className="generic-popup-treinta marker-popup__row__item">
              <div className={cellsClassname}>
                <div className="marker-popup__content-text__title">
                  {t("Velocidad")}
                </div>
                <div className="marker-popup__content-text__info">
                  {Math.round(vel)}
                </div>
              </div>
            </div>
            <div className="generic-popup-treinta marker-popup__row__item">
              {/* <Tooltip target={ id } title={ tIgn } arrow placement="right"> */}
              <div className={cellsClassname}>
                <div className="marker-popup__content-text__title">
                  {t("Tiempo")}
                </div>
                <div className="marker-popup__content-text__info">
                  {tIgn}
                  {/* tIgn.length > 8 ? tIgn.substr(0, 7) + "..." : tIgn */}
                </div>
              </div>
              {/* </Tooltip> */}
            </div>
            <div className="generic-popup-veinte marker-popup__row__item">
              <div className={cellsClassname}>
                <div className="marker-popup__content-text__title">
                  {t("Rumbo")}
                </div>
                <div className="marker-popup__content-text__info">{t(cur)}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="marker-popup__row">
          <div className="navigation-popup-dir marker-popup__row__item pad-vert-2 pad-hor-2">
            <div
              style={{ cursor: "pointer", display: "flex" }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps?q&layer=c&cbll=${lat},${lng}&cbp=11,0,0,0,0`,
                  "streeView",
                  "width=500,height=600"
                );
              }}
              title={t("Street View")}
            >
              <Streetview
                style={{
                  marginRight: "5px",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              />
              <div style={{ display: "block" }}>
                <div className="marker-popup__content-text__info">
                  {t("Fecha_GPS")}:
                  {format(
                    new Date(fGPSd),
                    GetDateFormat(this.props.user.language)
                  )}
                </div>
                <div className="marker-popup__content-text__info">{dir}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      toggleFillMarkerInfo,
      markerInfo,
      openPanel,
      changePanelType,
      t,
    } = this.props;

    const { isOpen } = this.state;
    const {
      pat,
      nInt,
      typ,
      mid,
      nMov,
      roadStatus,
      activeRouteName,
    } = markerInfo;

    const popupClassNames = classNames("marker-popup--navigation", {
      "marker-popup--navigation-open": isOpen,
    });
    const iconClassNames = classNames("marker-popup__content-icon", {
      [`marker-popup__content-icon--${typ}`]: typ,
    });

    function showActiveRoute(activeRouteName, roadStatus) {
      if (roadStatus && typ !== "inactive") {
        if (activeRouteName) {
          if (activeRouteName.length > 10)
            return activeRouteName.substr(0, 10) + "...";
          else return activeRouteName;
        }

        return "";
      }

      return "";
    }

    return (
      <>
        <MarkerPopup className={popupClassNames} variant={typ}>
          <div className="marker-popup__content">
            {this._buildExtendedRows(t)}
            <div className="marker-popup__row marker-popup__row--dark">
              <div className="marker-popup__row__item">
                <button
                  type="button"
                  id={`btnMarker_${mid}`}
                  className={iconClassNames}
                  onClick={this._handleToggleExtendedContent}
                >
                  <AssetIcon typeMobile={markerInfo.typeMobile} />
                </button>
                <div
                  className="marker-popup__row__item marker-popup__content-text pad-vert-2 pad-hor-2"
                  style={{ cursor: "pointer", textTransform: "capitalize" }}
                  // to={`/map/${eid}`}
                  onClick={() => {
                    openPanel();
                    changePanelType("GeneralInfo");
                    toggleFillMarkerInfo({ markerInfo: markerInfo });
                  }}
                >
                  {isOpen
                    ? pat.length > 10
                      ? pat.substr(0, 10) + "..."
                      : pat
                    : nMov + " - " + pat}
                </div>
              </div>
              {isOpen && (
                <div className="marker-popup__row__item">
                  <div className="marker-popup__content-text marker-popup__content-grow pad-vert-2 pad-hor-2">
                    {nInt ? nInt : showActiveRoute(activeRouteName, roadStatus)}
                  </div>
                  {markerInfo.asset && markerInfo.asset?.itSupportsOBD2 && (
                    <div className="marker-popup__content marker-popup__content-grow marker-popup__content-justify-right">
                      <OBD2ModalToggleButton
                        className={iconClassNames}
                        markerInfo={markerInfo}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </MarkerPopup>
        <Snackbar
          open={this.state.showAlert}
          autoHideDuration={4000}
          onClose={() => this.setState({ showAlert: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => this.setState({ showAlert: false })}
            severity="info"
            variant="filled"
          >
            {this.state.alertMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(withRouter(withTranslation()(NavigationPopup)));
