import React from "react";

import { IconButton } from "@material-ui/core";
import AssetIcon from "../Base/AssetIcon";
import { GetType } from "../../utils/generalUtils";

const AssetState = ({ point }) => {
  return (
    <IconButton
      style={{ marginTop: "25%" }}
      className={`assetState ${GetType(point.typ)}`}
    >
      <AssetIcon typeMobile={point?.asset?.typeMobile?.id} />
    </IconButton>
  );
};

export default AssetState;
