import React, { useState } from "react";

import GenericSelector from "../../../components/Selectors/GenericSelector";
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { format } from "date-fns";
import DateSelector from "../../../components/Selectors/DateSelector";

import { useTranslation } from "../../../lang";

const dateFormat = "yyyy-MM-dd HH:mm:ss";

const theme = createTheme({
  palette: {
    type: 'dark'
  }
});

const Filter = ({ assets, searchReport, errors }) => {
  const [t] = useTranslation();
  const [resumed, setResumed] = useState(false);
  const [dallasKeys, setDallasKeys] = useState(false);
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });

  const [dias, setDias] = useState([
    "LUNES",
    "MARTES",
    "MIERCOLES",
    "JUEVES",
    "VIERNES",
    "SABADO",
    "DOMINGO",
  ]);

  const [assetsSelected, setAssetsSelected] = useState();

  const callbackAssetSelected = (values) => {
    setAssetsSelected(values.map((v) => v.value));
  };

  const handleChangeResumed = (evt) => {
    setResumed(evt.target.checked);
  };

  const handleChangeDallasKeys = (evt) => {
    setDallasKeys(evt.target.checked);
  }

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: evt.fechaDesde,
        fechaHasta: evt.fechaHasta,
      });
    }

    if (evt.dias) setDias(evt.dias);
  };

  return (
    <div style={{ padding: "20px" }}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={5}>
            <FormGroup>
              <label className="formLabelFormik">{t("Fechas")}</label>
              <DateSelector onChange={handleChangePeriod} />
            </FormGroup>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <GenericSelector
              callbackOptionSelected={callbackAssetSelected}
              columns={columnsAssets}
              data={assets}
              keyField="id"
              labelField="completeName"
              title={t("Móviles")}
              isMulti
            />
            {errors && errors.assets && (
              <div className="input-feedback">{errors.assets}</div>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "15px" }}>
          <Grid item xs={5}>
            <FormControlLabel
              labelPlacement="start"
              style={{ marginLeft: "0" }}
              control={
                <Checkbox
                  checked={resumed}
                  onChange={handleChangeResumed}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t("Resumido")}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <FormControlLabel
              labelPlacement="start"
              style={{ marginLeft: "0" }}
              control={
                <Checkbox
                  checked={dallasKeys}
                  onChange={handleChangeDallasKeys}
                  name="checkedC"
                  color="primary"
                />
              }
              label={t("includeDallasKeys")}
            />
          </Grid>
        </Grid>
        <Grid container className="filterReportButtons">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              // console.log("onclick: ", dallasKeys);
              searchReport({
                assetsSelected,
                fechaDesde: fechas.fechaDesde,
                fechaHasta: fechas.fechaHasta,
                dias,
                resumed,
                useIbutton: dallasKeys,
                validations: [
                  {
                    field: assetsSelected,
                    fieldName: "assets",
                    errorMessage: t("Debe seleccionar al menos un móvil"),
                    // validationType: "required",
                  },
                ],
              })
            }
            }
          >
            {t("Generar Reporte")}
          </Button>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Filter;
