// eslint-disable react/forbid-prop-types
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";

import ModalImage from "react-modal-image";
import TabPanelContent from "../../Base/TabPanelContent";
import ImagenNoDisponible from "../resources/Imagen-no-disponible-282x300.png";
import { InfoRow } from "./GeneralInfo";
import "./GeneralInfoExtra.scss";
import DEVICE_QUERY from "../../../graphql/queries/getDeviceQuery";
import { useTranslation } from "../../../lang";
import GET_ASSET_QUERY from "../../../graphql/queries/getAssetQuery";
import GET_DRIVER_EXTRAINFO_QUERY from "../../../graphql/queries/driverExtraInfoQuery"

const ExtraContentImage = ({ vehiclePhoto, fullVehiclePhoto }) => (
  <div className="marker-info__extra-content__image-wrapper">
    {vehiclePhoto ? (
      <>
        <ModalImage
          small={vehiclePhoto}
          large={fullVehiclePhoto}
          className="centered-image"
        />
      </>
    ) : (
      <img src={ImagenNoDisponible} className="centered-image" />
    )}
  </div>
);

ExtraContentImage.propTypes = {
  vehiclePhoto: PropTypes.string,
};

ExtraContentImage.defaultProps = {
  vehiclePhoto: "",
};

const DriverImage = ({ driverPhoto }) => (
  <div className="marker-info__driver__image-wrapper">
    {driverPhoto && (
      <img
        src={driverPhoto}
        alt="Driver profile license potrait"
        className="centered-image"
      />
    )}
  </div>
);

DriverImage.propTypes = {
  driverPhoto: PropTypes.string,
};

DriverImage.defaultProps = {
  driverPhoto: "",
};

const ExtraContent = ({ markerInfo, index, value, assetInfo }) => {
  const [t] = useTranslation();
  const { nMov, nInt, nCli, pat, nCh, eid, mid, idCh } = markerInfo;

  const [device, setDevice] = useState();
  const [asset, setAsset] = useState();
  const [driver, setDriver] = useState();

  const [getDevice] = useLazyQuery(DEVICE_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      id: eid,
    },
    onCompleted: (data) => {
      setDevice(data.getDevice);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  const [getAsset] = useLazyQuery(GET_ASSET_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      assetId: mid,
    },
    onCompleted: (data) => {
      setAsset(data.asset);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  const [getDriver] = useLazyQuery(GET_DRIVER_EXTRAINFO_QUERY, {
    variables: {
      id: idCh
    },
    onCompleted: (data) => {
      setDriver(data.getDriver);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  })

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.roles.filter((r) => r.isOwner)) {
      getDevice();
    }
  }, [getDevice]);

  useEffect(() => {
    getAsset();
  }, [getAsset]);

  useEffect(() => {
    if(idCh) {
      getDriver();
    }
  }, [idCh]);

  return (
    <TabPanelContent
      value={value}
      index={index}
      subtitle={t("Información general")}
    >
      <div>
        {/* <Typography component="div" role="tabpanel"> */}
        {
          <ExtraContentImage
            //vehiclePhoto="/assets/img/truck-stock-image.png" /*{mFo}*/
            vehiclePhoto={assetInfo && assetInfo.photo}
            fullVehiclePhoto={assetInfo && assetInfo.photoFull}
          />
        }

        <InfoRow title={t("Cliente")} info={nCli} />
        <InfoRow title={t("Nombre vehículo")} info={nMov} />
        <InfoRow title={t("Num Interno")} info={nInt} />
        <InfoRow title={t("Placa")} info={pat} />
        <InfoRow title={t("Marca")} info={asset?.brand} />
        <InfoRow title={t("Color")} info={asset?.color} />
        <InfoRow title={t("Año")} info={asset?.year} />
        <InfoRow title={t("Modelo")} info={asset?.model} />
        <InfoRow
          title={t("Electrovalvula de corte")}
          info={asset?.fuelCutWiring ? t("Si") : t("No")}
        />
        <InfoRow title={t("Velocidad Maxima")} info={asset?.maximumSpeed} />

        <div className="marker-info__extra-content__driver">
          <DriverImage
            //driverPhoto="/assets/img/defaultAvatar.png" /*{cFo}*/
            driverPhoto={driver?.photo || "/assets/img/defaultAvatar.png"}
          />

          <div className="marker-info__driver__info">
            <div className="marker-info__driver__label">
              {t("Nombre del conductor")}
            </div>
            <div>{nCh}</div>
          </div>
        </div>
        {/* </Typography> */}
      </div>
      {device && (
        <>
          <div className="expandable-panel__tabs-item expandable-panel__info-block expandable-panel__info-block--dark">
            {t("Datos del Equipo")}
          </div>
          <InfoRow title={t("IMEI")} info={device.imei} />
          <InfoRow
            title={t("Tipo de Equipo")}
            info={device.deviceType?.description}
          />
          <InfoRow title={t("Modelo")} info={device.model} />
          <InfoRow title={t("Nro de Serie")} info={device.serialNumber} />
          <InfoRow
            title={t("Nro de Teléfono")}
            info={device?.line.map((l) => l.lineNumber).join(",")}
          />
          <InfoRow
            title={t("Versión del script")}
            info={device.scriptVersion}
          />
          <InfoRow
            title={t("Fecha de Instalación")}
            info={
              device.assetDevice?.dateAssociated &&
              new Date(device.assetDevice?.dateAssociated).toLocaleDateString(
                "es-ES"
              )
            }
          />
          <InfoRow
            title={t("Fecha de Alta Línea")}
            info={
              device.line?.createdAt &&
              new Date(device.line.createdAt).toLocaleDateString("es-ES")
            }
          />
        </>
      )}
    </TabPanelContent>
  );
};

ExtraContent.propTypes = {
  markerInfo: PropTypes.object,
};

ExtraContent.defaultProps = {
  markerInfo: {},
};

export default ExtraContent;
