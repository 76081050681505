import gql from "graphql-tag";

const ADDITIONAL_MAP_QUERY = gql`
  query AdditionalMaps {
    additionalMaps {
      id
      name
      url
    }
  }
`;

export default ADDITIONAL_MAP_QUERY;
