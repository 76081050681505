import gql from "graphql-tag";

const GET_MARKERS_SUBSCRIPTION = gql`
  subscription($groupsIDs: [Int]) {
    newPoint(groupsIDs: $groupsIDs) {
      eid
      id
      pat
      nMov
      nCli
      nCh
      lat
      lon
      vel
      cur
      fGPS
      tIgn
      tDet
      typ
      dir
      mid
      roadStatus
      activeRouteName
      evt
      odo
      utx
      asset {
        itSupportsOBD2
        typeMobile {
          id
        }
      }
    }
  }
`;

export default GET_MARKERS_SUBSCRIPTION;
