const ClusterIcon = `
    <svg
        width="60"
        height="60"
        viewBox="0 0 15.875 15.875"
    >
        <g transform="translate(0,-281.12498)">
            <circle
                class="cluster-marker-svg__background"
                style="fill:#000000;stroke-width:0.09299082"
                id="path20"
                cx="7.9375"
                cy="289.06247"
                r="7.9375" />
            <circle
                style="fill:#ffffff;stroke-width:0.06813497"
                id="path20-6"
                cx="7.9375"
                cy="289.06247"
                r="5.8158569" />
        </g>
    </svg>
`;

export default ClusterIcon;
