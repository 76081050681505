import PropTypes from "prop-types";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import ConnectedBaseMap from "../containers/ConnectedBaseMap";
import ConnectedMarkerInfo from "../containers/MarkerInfo/ConnectedMarkerInfo";
import PrivateRoute from "./PrivateRoute";
import { Suspense } from "react";
const MapBasePage = React.lazy(() => import("../pages/Map/Base"));

export default class MapRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    match: {},
  };

  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <>
        <Route
          path={`${path}`}
          exact
          component={(routerProps) => (
            <Suspense fallback={<></>}>
              <PrivateRoute component={MapBasePage} {...routerProps} />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/:markerId`}
          exact
          component={(routerProps) => (
            <Suspense fallback={<></>}>
              <PrivateRoute
                component={MapBasePage}
                {...routerProps}
                lateralBar={ConnectedMarkerInfo}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/users`}
          exact
          component={(routerProps) => <Suspense fallback={<></>}><MapBasePage {...routerProps} /></Suspense>}
        />

        <ConnectedBaseMap />
      </>
    );
  }
}
