import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MiniMap from "../../../components/Base/MiniMap";
import Table from "../../../components/Base/Table";
import { useTranslation } from "../../../lang";
import { GetDateFormat, secondsToTime } from "../../../utils/generalUtils";

const ReportResult = ({ reportData, user }) => {
  const [t] = useTranslation();
  const [detalle, setDetalle] = useState();
  const [rowSelected, setRowSelected] = useState(0);
  const [miniMapData, setMiniMapData] = useState({
    lat: 0,
    lon: 0,
    title: "",
    tooltipText: "",
  });

  const [isMiniMapOpen, setIsMiniMapOpen] = useState(false);
  const closeMiniMap = () => setIsMiniMapOpen(false);

  const shouldAllowOpenMiniMap = (row) => {
    const ALLOWED_DETENTION_STATUS = ["STOPPED", "IDLE"];
    const shouldAllow = ALLOWED_DETENTION_STATUS.includes(row.detentionStatus);
    return shouldAllow;
  };

  useEffect(() => {
    setDetalle(reportData[0].detentionReports);
  }, [reportData]);

  const summaryRowEvents = {
    onClick: (e, row, rowIndex) => {
      const assetData = reportData.find((rd) => rd.mid === row.mid);
      setDetalle(assetData.detentionReports);
      setRowSelected(rowIndex);
    },
  };

  const handleOpenMiniMap = (row) => {
    const header = reportData[rowSelected];
    setMiniMapData({
      lat: row.lat,
      lon: row.lon,
      title: `${t("Detención")}: ${header.name}`,
      tooltipText: header.name,
    });
    setIsMiniMapOpen(true);
  };

  const columnsSummary = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
    },
    {
      dataField: "nInt",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "brand",
      text: t("Marca"),
      sort: true,
    },
    {
      dataField: "typeMobileDescription",
      text: t("Tipo Movil"),
      sort: true,
    },
  ];

  /*nCh: String
uIni: String
iTime: Datetime
uFin: String
fTime: Datetime
detentionStatus: DetentionStatus
detentionTime: Int
lat: Float
lon: Float*/

  const columnsDetail = [
    {
      dataField: "nCh",
      text: t("Conductor"),
      sort: true,
      hidden: true,
    },
    {
      dataField: "iTime",
      text: t("Inicio"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.iTime), GetDateFormat(user.language)),
    },
    {
      dataField: "fTime",
      text: t("Fin"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.fTime), GetDateFormat(user.language)),
    },
    {
      dataField: "uIni",
      text: t("Lugar"),
      sort: true,
    },
    {
      dataField: "detentionStatus",
      text: t("Estado"),
      sort: true,
      formatter: (cell, row) => t(row.detentionStatus),
    },
    {
      dataField: "detentionTime",
      text: t("Duración detención"),
      sort: true,
      formatter: (cell, row) => secondsToTime(row.detentionTime),
    },
    {
      dataField: "id",
      text: t("Ver punto"),
      sort: true,
      headerStyle: () => ({
        width: "10%",
        fontSize: "12px",
        textAlign: "center",
      }),
      formatter: (cell, row) => {
        return shouldAllowOpenMiniMap(row) ? (
          <IconButton
            style={{ padding: "0" }}
            onClick={() => handleOpenMiniMap(row)}
          >
            <SearchIcon className="SearchIconColor" />
          </IconButton>
        ) : (
          <div />
        );
      },
    },
  ];

  const rowSummaryStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex === rowSelected) {
      // style.background = "#1c3663";
      style.background = "lightgrey";
    }

    return style;
  };

  return (
    <div>
      <MiniMap
        isOpen={isMiniMapOpen}
        closeModal={closeMiniMap}
        {...miniMapData}
      />
      <div className="resultPart">
        <Table
          columns={columnsSummary}
          data={reportData}
          keyField="mid"
          // rowEvents={summaryRowEvents}
          rowStyle={rowSummaryStyle}
        />
      </div>
      <div className="resultPart">
        <Table columns={columnsDetail} data={detalle} keyField="id" />
      </div>
    </div>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportResult);
