import gql from "graphql-tag";

const ACKMULTIPLEALARMS_MUTATION = gql`
  mutation AckMultipleAlarms(
    $typeNotificationId: Int
    $typeAlarmNotifications: [Int],
    $assetid: Int
    $date: Datetime
    $typeAlarm: [TypeAlarm]
  ) {
    ACKMultipleAlarms(
      typeNotificationId: $typeNotificationId
      typeAlarmNotifications: $typeAlarmNotifications
      assetid: $assetid
      date: $date
      typeAlarm: $typeAlarm
    )
  }
`;

export default ACKMULTIPLEALARMS_MUTATION;