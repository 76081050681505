import "leaflet-plugin-trackplayback";
import L from "leaflet";

const getAssetIcon = (typeMobileId) => {
  switch (typeMobileId) {
    case 1:
      return '/assets/img/Minivan.png';
    case 2:
      return '/assets/img/Truck.png';
    case 3:
      return '/assets/img/Man.png';
    case 4:
      return '/assets/img/Tracktor.png';
    case 5:
      return '/assets/img/Sport.png';
    case 6:
      return '/assets/img/Custer.png';
    case 7:
      return '/assets/img/Bus.png';
    case 8:
      return '/assets/img/Station.png';
    case 9:
      return '/assets/img/Van.png';
    case 10:
      return '/assets/img/Sedan.png';
    case 11:
      return '/assets/img/Icon4x4.png';
    case 12:
      return '/assets/img/Moto.png';
    default:
      return '/assets/img/Bus.png';
  }
}

const getDivContainer = () => {
  const container = L.DomUtil.create(
    "div",
    "leaflet-bar leaflet-control leaflet-control-custom"
  );
  container.style.backgroundColor = "white";
  container.style.width = "230px";
  container.style.height = "42px";
  container.style.border = "1px solid black";
  container.style.marginTop = "100px";
  container.style.position = "relative";
  container.style.display = "block";

  return container;
};

const getInnerContainer = () => {
  const container = L.DomUtil.create("div");

  container.style.margin = "0";
  container.style.position = "absolute";
  container.style.top = "50%";
  container.style.transform = "translateY(-50%)";

  return container;
};

const actionIconStyle = {
  width: "32px",
  height: "32px",
  backgroundSize: "32px 32px",
  border: "0",
  backgroundColor: "transparent",
  margin: "5px 0 0 10px",
};

const getPlayButton = (t) => {
  const playButton = L.DomUtil.create("button");

  playButton.style["backgroundImage"] = "url(/assets/img/playIcon.png)";
  playButton.title = t("start");

  //style object is readonly so we can't assign a new object with the merged styles
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    playButton.style[key] = value;
  });

  return playButton;
};

const getPauseButton = (t) => {
  const pauseButton = L.DomUtil.create("button");

  pauseButton.style.backgroundImage = "url(/assets/img/pauseIcon.png)";
  pauseButton.style.display = "none";
  pauseButton.title = t("pause");
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    pauseButton.style[key] = value;
  });

  return pauseButton;
};

const getRewindButton = (t) => {
  const rewindButton = L.DomUtil.create("button");

  rewindButton.style.backgroundImage = "url(/assets/img/replayIcon.png)";
  rewindButton.title = t("rewind");
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    rewindButton.style[key] = value;
  });

  return rewindButton;
};

const getSlowButton = (t) => {
  const slowButton = L.DomUtil.create("button");

  slowButton.style.backgroundImage = "url(/assets/img/slowIcon.png)";
  slowButton.title = t("slow");
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    slowButton.style[key] = value;
  });

  return slowButton;
};

const getFastButton = (t) => {
  const fastButton = L.DomUtil.create("button");

  fastButton.style.backgroundImage = "url(/assets/img/fastIcon.png)";
  fastButton.title = t("faster");
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    fastButton.style[key] = value;
  });

  return fastButton;
};

const getClearButton = (t) => {
  const clearButton = L.DomUtil.create("button");

  clearButton.style.backgroundImage = "url(/assets/img/clearIcon.png)";
  clearButton.title = t("clear");
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    clearButton.style[key] = value;
  });

  return clearButton;
};

const getControlInstance = (
  mapInstance,
  track,
  playbackTickHandler,
  closePanel,
  updateMarkerJourney,
  onReady = () => { },
  onRemove = () => { },
  t,
  typeMobileId
) => {
  const trackOptions = {
    clockOptions: {
      speed: 6,
    },
    trackPointOptions: {
      useCanvas: false,
    },
    targetOptions: {
      useImg: true,
      imgUrl: getAssetIcon(typeMobileId),
      width: 48,
      height: 48,
      color: "#00f",
      fillColor: "#9FD12D",
    },
  };

  if (track && track.length > 0) {
    const trackplayback = L.trackplayback(track, mapInstance, trackOptions);

    trackplayback.on("tick", (e) => {
      const { time, target } = e;
      const matchPoint =
        target &&
        target.tracks &&
        target.tracks[0]._trackPoints.filter(
          (p) => parseInt(p.time, 10) === parseInt(time, 10)
        );

      if (matchPoint && matchPoint.length > 0) {
        playbackTickHandler(matchPoint[0].info[0].value);
      }
    });

    const playControl = L.Control.extend({
      options: {
        position: "topright",
      },
      onAdd: () => {
        const divContainer = getDivContainer();
        const innerContainer = getInnerContainer();
        const playButton = getPlayButton(t);
        const pauseButton = getPauseButton(t);
        const rewindButton = getRewindButton(t);
        const slowButton = getSlowButton(t);
        const fastButton = getFastButton(t);
        const clearButton = getClearButton(t);

        playButton.onclick = () => {
          trackplayback.start();
          playButton.style.display = "none";
          pauseButton.style.display = "inline-block";
        };

        pauseButton.onclick = () => {
          trackplayback.stop();
          playButton.style.display = "inline-block";
          pauseButton.style.display = "none";
        };

        rewindButton.onclick = () => trackplayback.rePlaying();
        slowButton.onclick = () => trackplayback.slowSpeed();
        fastButton.onclick = () => trackplayback.quickSpeed();
        clearButton.onclick = () => {
          if (trackplayback) {
            //divContainer.remove();
            trackplayback.stop();
            trackplayback.dispose();
            updateMarkerJourney && updateMarkerJourney({});
            closePanel && closePanel();
          }
          divContainer.style.display = "none";
        };

        divContainer.appendChild(innerContainer);
        innerContainer.appendChild(playButton);
        innerContainer.appendChild(pauseButton);
        innerContainer.appendChild(rewindButton);
        innerContainer.appendChild(slowButton);
        innerContainer.appendChild(fastButton);
        innerContainer.appendChild(clearButton);

        onReady();
        return divContainer;
      },
      onRemove: () => {
        try {
          if (trackplayback) {
            trackplayback.stop();
            trackplayback.dispose();
          }
        } catch (err) {
          //
        }
      },
    });

    const pc = new playControl();
    return pc;
  }
  return null;
};

export default getControlInstance;
