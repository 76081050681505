import React, { useEffect, useState } from "react";

import { Grid, IconButton } from "@material-ui/core";

import GenericSelector from "../Selectors/GenericSelector";
import PlusSquare from "../../public/icons/plus-square.png";

import { useTranslation } from "../../lang";

// const dateFormat = "yyyy-MM-dd HH:mm:ss";

const FilterRevisionNotice = ({
  mid,
  assetList,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
  handleGetRevisionNotices,
  setMidSelected,
  language,
}) => {
  const [t] = useTranslation();

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  // useEffect(() => {
  //   changePanelAuxTitle(t("Nuevo aviso de revisión"));
  // }, [language]);

  const onAssetSelect = (assets) => {
    //setAssetsSelected(assets ? assets.map(a => a.value) : []);
    handleGetRevisionNotices(assets ? assets.map((a) => a.value) : []);
  };

  return (
    <Grid container className="revisionFilterContainer">
      <Grid item xs={10} style={{ marginTop: "-25px" }}>
        {!mid && (
          <GenericSelector
            callbackOptionSelected={(value) => onAssetSelect(value)}
            columns={columns}
            isMulti
            keyField="id"
            labelField="name"
            title={t("Móviles")}
            data={assetList}
          />
        )}
      </Grid>

      <Grid item xs={1} />

      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            changePanelAuxTitle(t("Nuevo aviso de revisión"));
            changePanelAuxType("RevisionNotice");
            openPanelAux();
            mid && setMidSelected(mid);
            //handleGetRevisionNotices(assetsSelected);
          }}
        >
          <img src={PlusSquare} style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default FilterRevisionNotice;
