// eslint-disable react/forbid-prop-types
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Streetview from "@material-ui/icons/Streetview";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { format } from "date-fns";
import { useLazyQuery } from "@apollo/client";

import TabPanelContent from "../../Base/TabPanelContent";
import FlexRow from "../../Base/FlexRow";

import "./GeneralInfo.scss";
import { makeStyles, Popover, Typography } from "@material-ui/core";

import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
import GET_HISTORY_POINT_ENTENDED_DATA from "../../../graphql/queries/getHistoryPointExtendedData";
import GET_MARKERS_SUBSCRIPTION_BY_ASSETID from "../../../graphql/subscriptions/markersByAssetIdSubscription";
import { markersSelector } from "../../../redux/markers/selector";
import clientSubscription from "../../Base/apiApolloClientSubscription";

export const InfoRow = ({ title, info }) => (
  <FlexRow
    bottomDivider
    expansion=""
    alignment="space-between"
    _classNames="pad-4"
  >
    <div>{title}</div>
    <div title={info} className="infoRow_content">{info}</div>
  </FlexRow>
);
InfoRow.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
};

InfoRow.defaultProps = {
  title: "",
  info: "",
};

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const GeneralInfo = ({
  value,
  markerInfo,
  toggleActivePanelAux,
  index,
  toggleActivePanel,
  toggleFillMarkerInfoAux,
  user,
  toggleFillMarkerInfo
}) => {
  const [t] = useTranslation();
  const {
    eid: eid,
    cur: cur,
    fGPS: fGPS,
    id: id,
    nCh: nCh,
    nCli: nCli,
    nMov: nMov,
    pat: pat,
    pos: pos,
    tDet: tDet,
    tIgn: tIgn,
    typ: typ,
    vel: vel,
    dir: dir,
    cmb: cmb,
    odo: odo,
    sOdo: sOdo,
    horo: horo,
    utx: utx,
    mid: mid
  } = markerInfo;

  const [copySuccess, setCopySuccess] = useState("");
  const copyRef = useRef(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [healthData, setHealthData] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  let positionString = "";

  const subscribeToPointsByAssetId = (assetId) => {
    let subscription = null;

    if (+assetId > 0) {
      const observer = clientSubscription.subscribe({
        query: GET_MARKERS_SUBSCRIPTION_BY_ASSETID,
        variables: {
          assetId: +assetId,
        },
      });

      subscription = observer.subscribe({
        next(res) {
          successNewPointCallback(res.data.newPointByAssetId);
        },
        error(err) {
          console.error("err", err);
        },
      });
    }

    return subscription;
  };

  useEffect(() => {
    let subscription = null;

    if(mid) {
      subscription = subscribeToPointsByAssetId(mid);
    }

    return () => subscription && subscription.unsubscribe();
  }, [mid]);

  const successNewPointCallback = (data) => {
    console.log('sub by id: ', data);
    const point = markersSelector({
      markers: [data],
      t,
    });
    console.log('point: ', point);

    toggleFillMarkerInfo(point[0]);
  };

  if (pos != null && pos.lat != "" && pos.lng != "") {
    const lat = pos.lat;
    const lng = pos.lng;
    positionString = `${lat}, ${lng}`;
  }

  const unit = t("kmh");
  const handleToggleActivePanel = () => {
    toggleFillMarkerInfoAux({
      isOpen: true,
      markerInfo: markerInfo,
      panelAuxComponent: "GeneralInfoExtra",
      toggleActivePanelAux: toggleActivePanelAux,
    });
  };

  const copyToClipboard = (e, text, t) => {
    const ta = document.createElement("textarea");
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
    setCopySuccess(t("Copiado al portapapeles!"));
    handleClick(e);
  };

  const variables = {
    id: markerInfo.id,
  };

  const [getHealthData] = useLazyQuery(GET_HISTORY_POINT_ENTENDED_DATA, {
    variables,
    fetchPolicy: "no-cache",
    onCompleted: (d) => {
      setHealthData({
        batteryVoltage: d.getHistoryPointExtendedData?.batteryVoltage,
        externalVoltage: d.getHistoryPointExtendedData?.externalVoltage,
        GNSSStat: d.getHistoryPointExtendedData?.GNSSStat,
      });
    },
    onError: (error) => {
      console.log("onError");
      console.log("Error:", error);
    },
  });

  useEffect(() => {
    if (markerInfo.id && process.env.REACT_APP_CUSTOMER === 'Reaxium') getHealthData();
  }, [markerInfo]);

  return (
    <TabPanelContent
      value={value}
      index={index}
      subtitle={t("Información general")}
    >
      <div>
        <div className="expandable-panel__tabs-item expandable-panel__info-block expandable-panel__info-block--dark">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q&layer=c&cbll=${pos.lat},${pos.lng}&cbp=11,0,0,0,0`,
                "streeView",
                "width=500,height=600"
              );
            }}
            title={t("Street View")}
          >
            <Streetview />
          </div>

          <div className="expandable-panel__tabs-item expandable-panel__info-block-content">
            <div>{dir}</div>
            <div ref={copyRef}>{positionString}</div>
          </div>

          <div>
            <FileCopyIcon
              aria-describedby={popoverId}
              onClick={(e) => copyToClipboard(e, positionString, t)}
              style={{ cursor: "pointer" }}
              titleAccess={t("Copiar Lat/Lon")}
            />
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className={classes.typography}>
                {copySuccess}
              </Typography>
            </Popover>
          </div>
        </div>

        <InfoRow title={t("Orientación")} info={t(cur)} />
        <InfoRow title={t("Velocidad")} info={`${vel} ${unit}`} />
        <InfoRow title={t("Odómetro")} info={String(odo)} />
        <InfoRow title={t("Odómetro software")} info={String(sOdo)} />
        <InfoRow title={t("Horómetro")} info={horo} />
        {/* <InfoRow title="Fecha GPS" info={fGPS} /> */}
        <InfoRow title={t("Combustible")} info={cmb ? String(cmb) : "S/D"} />
        <InfoRow
          title={t("Última transmisión")}
          info={utx && format(new Date(utx), GetDateFormat(user.language))}
        />
        <InfoRow title={t("Tiempo detención")} info={tDet} />
        {healthData && (
          <>
            <InfoRow
              title={t("Voltaje Externo")}
              info={
                (healthData.externalVoltage
                  ? healthData.externalVoltage / 1000
                  : "N/A") + " [v]"
              }
            />
            <InfoRow
              title={t("Voltaje Batería")}
              info={
                (healthData.batteryVoltage
                  ? healthData.batteryVoltage / 1000
                  : "N/A") + " [v]"
              }
            />
            <InfoRow
              title={t("GNSS Stat")}
              info={healthData?.GNSSStat || "N/A"}
            />
          </>
        )}

        <div className="expandable-panel__tabs-item">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleToggleActivePanel}
          >
            {t("Ver detalles")}
          </Button>
        </div>
      </div>
    </TabPanelContent>
  );
};

GeneralInfo.propTypes = {
  value: PropTypes.number,
  markerInfo: PropTypes.object,
  index: PropTypes.number,
  toggleActivePanelAux: PropTypes.func,
  toggleActivePanel: PropTypes.func,
  toggleFillMarkerInfoAux: PropTypes.func,
};

GeneralInfo.defaultProps = {
  value: 0,
  markerInfo: {},
  index: 0,
  toggleActivePanelAux: () => {},
  toggleActivePanel: () => {},
  toggleFillMarkerInfoAux: () => {},
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(GeneralInfo);
