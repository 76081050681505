import React, { Component } from "react";
import PropTypes from "prop-types";
import NavigationRouting from "./NavigationRouting";
//import TrackPlayer from "./TrackPlayer";

export default class MarkersJourneys extends Component {
  static propTypes = {
    journeys: PropTypes.object,
    showJourney: PropTypes.bool,
    toggleFillGenericPopup: PropTypes.func,
  };

  static defaultProps = {
    journeys: {},
    showJourney: false,
    toggleFillGenericPopup: () => {},
  };

  render() {
    const { journeys, showJourney, toggleFillGenericPopup } = this.props;

    if (Object.keys(journeys).length !== 0) {
      return (
        <NavigationRouting
          journeys={journeys}
          showJourney={showJourney}
          toggleFillGenericPopup={toggleFillGenericPopup}
        />
      );
    } else {
      return <></>;
    }
  }
}
