import React, { useEffect, useState } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { useLazyQuery } from "@apollo/client";

import { AVAILABLE_MAP_TILES } from "../../constants/map";
import ADDITIONAL_MAP_QUERY from "../../graphql/queries/additionalMapsQuery";

import "./BaseLayersControl.scss";

const { BaseLayer, Overlay } = LayersControl;

const BaseLayersControlV3 = ({ zoom, layerName }) => {
  const [additionalMaps, setAdditionalMaps] = useState([]);
  const showPeruMaps =
    process.env.REACT_APP_SHOW_PERU_MAPS === "True" ? true : false;

  const osmLayerName =
    process.env.REACT_APP_SHOW_PERU_MAPS === "True" ? "OSM" : "Default View";

  const satellitalLayerName =
    process.env.REACT_APP_SHOW_PERU_MAPS === "True"
      ? "Satellite Maps"
      : "Satellite View";

  const [fetchAdditionalMaps] = useLazyQuery(ADDITIONAL_MAP_QUERY, {
    onCompleted(d) {
      setAdditionalMaps(d.additionalMaps);
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    fetchAdditionalMaps();
  }, []);

  return (
    <LayersControl position="bottomright">
      <BaseLayer
        name={satellitalLayerName}
        checked={layerName === { satellitalLayerName } ? true : false}
      >
        <TileLayer
          url={AVAILABLE_MAP_TILES.argis.tile}
          layers={AVAILABLE_MAP_TILES.argis.layer}
          maxZoom={17}
          zIndex={layerName === { satellitalLayerName } ? 9999 : 1}
        />
      </BaseLayer>

      <BaseLayer
        name={osmLayerName}
        checked={layerName === osmLayerName ? true : false}
      >
        <TileLayer
          url={AVAILABLE_MAP_TILES.default.tile}
          maxZoom={20}
          zIndex={layerName === osmLayerName ? 9999 : 1}
        />
      </BaseLayer>

      {additionalMaps.map((ad) => (
        <BaseLayer key={ad.id} name={ad.name} checked={layerName === ad.name}>
          <TileLayer url={ad.url} zIndex={layerName === ad.name ? 9999 : 1} />
        </BaseLayer>
      ))}

      {showPeruMaps && zoom <= 15 && (
        <BaseLayer
          name="Street Maps"
          checked={layerName === "Street Maps" ? true : false}
        >
          <WMSTileLayer
            url={AVAILABLE_MAP_TILES.openGeo.tile}
            maxZoom={15}
            layers={AVAILABLE_MAP_TILES.openGeo.layer}
            zIndex={layerName === "Street Maps" ? 9999 : 1}
          />
        </BaseLayer>
      )}

      {showPeruMaps && zoom >= 16 && (
        <BaseLayer
          name="Street Maps"
          checked={layerName === "Street Maps" ? true : false}
        >
          <TileLayer
            url={AVAILABLE_MAP_TILES.openGeo16.tile}
            layers={AVAILABLE_MAP_TILES.openGeo16.layer}
            maxZoom={22}
            zIndex={layerName === "Street Maps" ? 9999 : 1}
          />
        </BaseLayer>
      )}

      {showPeruMaps && (
        <Overlay name="Route Km Maps">
          <WMSTileLayer
            url={AVAILABLE_MAP_TILES.openGeoKm.tile}
            layers={AVAILABLE_MAP_TILES.openGeoKm.layer}
            format="image/png"
            transparency
          />
        </Overlay>
      )}
    </LayersControl>
  );
};

export default React.memo(BaseLayersControlV3);
