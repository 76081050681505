import React from "react";
import { FieldProps } from "formik";
import Select from "react-select";

import CustomStyles from "../../utils/reactSelectStyle";

import { useTranslation } from "../../lang";

const CustomFormikSelect = ({
  options,
  field,
  form,
  isMulti,
  onChange = () => {},
}) => {
  const [t] = useTranslation();
  let value = "";

  if (isMulti) {
    value =
      options && field.value
        ? options.filter((x) =>
            field.value.includes(x.value)
          )
        : null;
  } else {
    value = options
      ? options.find((option) => option.value === field.value)
      : "";
  }

  return (
    <Select
      options={options}
      name={field.name}
      value={value}
      onChange={(option) => {
        form.setFieldValue(
          field.name,
          isMulti ? option && option.map((o) => o.value) : option.value
        );
        onChange(option);
      }}
      onBlur={field.onBlur}
      placeholder={t("Seleccionar_")}
      styles={CustomStyles}
      isMulti={isMulti}
    />
  );
};

export default CustomFormikSelect;
