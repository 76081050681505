import React from "react";
import { connect } from "react-redux";

import IconsContainer from "./IconsContainer";
import Panel from "./Panel";
import PanelAux from "./PanelAux";

import {
  openPanel,
  openPanelAux,
  closePanel,
  closePanelAux,
  changePanelType,
  onChangeTab,
  seeAllFleet,
} from "../../redux/lateralPanel/actions";

import {
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourneyPersonalizado,
  _updateMarkerJourney,
} from "../../redux/fillMarkerJourney/actions";


import { toggleFillMarkerInfoAux, updateMarkerInfo } from "../../redux/fillMarkerInfo/actions";
import { toggleFillGenericPopup } from "../../redux/genericPopup/actions";

const NewLateralBar = ({
  isPanelOpen,
  isPanelAuxOpen,
  panelType,
  openPanel,
  openPanelAux,
  closePanel,
  closePanelAux,
  changePanelType,
  markerInfo,
  markerJourney,
  onChangeTab,
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillMarkerInfoAux,
  toggleFillGenericPopup,
  lateralAuxInfo,
  tabActive,
  panelAuxType,
  panelAuxTitle,
  seeAllFleet,
  loggedUser,
  updateMarkerJourney,
  toggleFillMarkerInfo
}) => {
  return (
    <>
      <IconsContainer
        openPanel={openPanel}
        openPanelAux={openPanelAux}
        closePanel={closePanel}
        closePanelAux={closePanelAux}
        changePanelType={changePanelType}
        loggedUser={loggedUser}
      />

      {isPanelOpen && (
        <Panel
          panelType={panelType}
          openPanel={openPanel}
          closePanel={closePanel}
          markerInfo={markerInfo}
          markerJourney={markerJourney}
          openPanelAux={openPanelAux}
          closePanelAux={closePanelAux}
          onChangeTab={onChangeTab}
          toggleFillMarkerJourney={toggleFillMarkerJourney}
          toggleFillMarkerJourney2={toggleFillMarkerJourney2}
          toggleFillMarkerJourneyPersonalizado={
            toggleFillMarkerJourneyPersonalizado
          }
          toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
          toggleFillGenericPopup={toggleFillGenericPopup}
          tabActive={tabActive}
          changePanelType={changePanelType}
          title={panelAuxTitle}
          seeAllFleet={seeAllFleet}
          updateMarkerJourney={updateMarkerJourney}
          toggleFillMarkerInfo={toggleFillMarkerInfo}
        />
      )}

      {isPanelAuxOpen && (
        <PanelAux
          panelType={panelType}
          tabActive={tabActive}
          closePanel={closePanelAux}
          markerInfo={markerInfo}
          lateralAuxInfo={lateralAuxInfo}
          markerJourney={markerJourney}
          openPanelAux={openPanelAux}
          onChangeTab={onChangeTab}
          toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
          toggleFillMarkerJourneyPersonalizado={
            toggleFillMarkerJourneyPersonalizado
          }
          toggleFillMarkerJourney={toggleFillMarkerJourney}
          toggleFillMarkerJourney2={toggleFillMarkerJourney2}
          toggleFillGenericPopup={toggleFillGenericPopup}
          typeContent={panelAuxType}
          title={panelAuxTitle}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.lateralPanel.newPanelReducer,
    lateralAuxInfo: state.lateralPanel.panelAuxInfo,
    markerInfo: state.markerInfo,
    markerJourney: state.markerJourney,
    loggedUser: state.user,
    //panelAuxType: state.lateralPanel.panelAuxType,
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    openPanel: () => dispatch(openPanel()),
    openPanelAux: () => dispatch(openPanelAux()),
    closePanel: () => dispatch(closePanel()),
    closePanelAux: () => dispatch(closePanelAux()),
    changePanelType: (panelType) => dispatch(changePanelType(panelType)),
    onChangeTab: (tab) => dispatch(onChangeTab(tab)),
    toggleFillMarkerJourney: (param) =>
      toggleFillMarkerJourney(param)(dispatch),
    toggleFillMarkerJourney2: (param) =>
      toggleFillMarkerJourney2(param)(dispatch),
    toggleFillMarkerJourneyPersonalizado: (param) =>
      toggleFillMarkerJourneyPersonalizado(param)(dispatch),
    toggleFillMarkerInfoAux: (param) =>
      toggleFillMarkerInfoAux(param)(dispatch),
    toggleFillGenericPopup: (param) => toggleFillGenericPopup(param)(dispatch),
    seeAllFleet: (param) => dispatch(seeAllFleet(param)),
    updateMarkerJourney: (param) => dispatch(_updateMarkerJourney(param)),
    toggleFillMarkerInfo: (param) => updateMarkerInfo(param)(dispatch, getState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLateralBar);
