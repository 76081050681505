import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducer from "../redux/rootReducer";

import { configureInitialState, configureStore } from "../utils/store";
import Loading from "../components/Base/Loading";

const ManageBasePage = React.lazy(() => import("../pages/Manage/Base"));
const ManageDevicesPage = React.lazy(() => import("../pages/Manage/Devices"));
const PrivateRoute = React.lazy(() => import("./PrivateRoute"));
const ManageTechnicianPage = React.lazy(() => import("../pages/Manage/Technicians"));
const ManageLinePage = React.lazy(() => import("../pages/Manage/Lines"));
const ManageUserPage = React.lazy(() => import("../pages/Manage/Users"));
const ManageRolePage = React.lazy(() => import("../pages/Manage/Roles"));
const ManageDriverPage = React.lazy(() => import("../pages/Manage/Drivers"));
const ManageAssetPage = React.lazy(() => import("../pages/Manage/Assets"));
const ManageGroupsPage = React.lazy(() => import("../pages/Manage/Groups"));
const ManageGeofencePage = React.lazy(() => import("../pages/Manage/Geofences"));
const ManageReportPlanningPage = React.lazy(() => import("../pages/Manage/ReportPlanning"));

const ManagePOIPage = React.lazy(() => import("../pages/Manage/POIs"));
const ManageGuidNumberPage = React.lazy(() => import("../pages/Manage/GuideNumber"));
const ManageRoadPage = React.lazy(() => import("../pages/Manage/Routes"));
const ManageNotificationConfiguration = React.lazy(() => import("../pages/Manage/NotificationConfiguration"));
const ManageClientsPage = React.lazy(() => import("../pages/Manage/Clients"));

export default class ManageRoutes extends Component {
  static propTypes = {
    path: PropTypes.string,
  };

  static defaultProps = {
    path: "",
  };

  constructor(props) {
    super(props);

    /**
     * Let's create a store with redux-thunk and set the initial state
     * If you need to add or modify objects in the store, remember to start
     * adding them to the 'configureInitialState' method
     */
    this._store = configureStore({
      reducer,
      initialState: configureInitialState(props),
      // eslint-disable-next-line comma-dangle
      middleware: [thunk],
    });
  }

  render() {
    const { path } = this.props;
    return (
      <Provider store={this._store} path={path}>
        <Route
          path={`${path}/`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/`}
                component={ManageBasePage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/clients`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/clients`}
                permission="Clientes"
                component={ManageClientsPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/devices`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/devices`}
                permission="Equipos"
                component={ManageDevicesPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/technicians`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/technicians`}
                permission="Técnicos"
                component={ManageTechnicianPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/lines`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/lines`}
                permission="Lineas"
                component={ManageLinePage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/users`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/users`}
                permission="Usuarios"
                component={ManageUserPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/roles`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/roles`}
                permission="Roles"
                component={ManageRolePage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/drivers`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/drivers`}
                permission="Conductores"
                component={ManageDriverPage}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/assets`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/assets`}
                permission="Moviles"
                component={ManageAssetPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/groups`}
          exact
          component={(routerParams) => (
            <Suspense fallback={true}>
              <PrivateRoute
                path={`${path}/groups`}
                permission="Grupos"
                component={ManageGroupsPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/geofences`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/geofences`}
                permission="Geocercas"
                component={ManageGeofencePage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/reportPlanning`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/reportPlanning`}
                permission="Planificador"
                component={ManageReportPlanningPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/pois`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/pois`}
                permission="PuntosInteres"
                component={ManagePOIPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />

        <Route
          path={`${path}/trackingnumber`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/trackingnumber`}
                permission="AsignarNroGuia"
                component={ManageGuidNumberPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/road`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/road`}
                permission="Rutas"
                component={ManageRoadPage}
                {...routerParams}
              />
            </Suspense>
          )}
        />
        <Route
          path={`${path}/confNotifications`}
          exact
          component={(routerParams) => (
            <Suspense fallback={<Loading working={true} />}>
              <PrivateRoute
                path={`${path}/confNotifications`}
                permission="Planificador"
                component={ManageNotificationConfiguration}
                {...routerParams}
              />
            </Suspense>
          )}
        />
      </Provider>
    );
  }
}
