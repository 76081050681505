import React from "react";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// @TODO rename import with a more semantic name
import ChangeLanguageForm from "./Form";

import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const SelecteLanguageModal = ({ isOpen, closeModal }) => {
  const [t] = useTranslation();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Cambiar Idioma")}
          </DialogTitle>
          <DialogContent className="dialogContent">
            <ChangeLanguageForm closeModal={closeModal} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default SelecteLanguageModal;
