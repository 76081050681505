import gql from "graphql-tag";

const TYPEALARM_NOTIFICATION_QUERY = gql`
  query {
    typeAlarmNotification {
      id
      event
      typeAlarm
    }
  }
`;

export default TYPEALARM_NOTIFICATION_QUERY;