import React, { useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Card, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { useHistory, useLocation } from "react-router";
import { useLazyQuery } from "@apollo/client";

import { ReduxTextField } from "../Base/ReduxFormFields";
import { useTranslation } from "../../lang";
import POINT_GUIDENUMBER_QUERY from "../../graphql/queries/getPointGuidNumberQuery";

const Form = ({ handleSearchGuidNumber }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const isCargoTrackingPage = useMemo(() => location.pathname === '/cargoTracking', [location.pathname]);
  const cargoTrackingStyle = {
    InputProps: {
      style: { color: isCargoTrackingPage ? '#fff' : '#000 !important' }
    },
    InputLabelProps: {
      style: { color: isCargoTrackingPage ? '#fff' : '#000 !important'}
    }
  }
  

  const [guideNumber, setGuideNumber] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const handleChangeRecaptcha = (value) => {
    setIsCaptchaValid(true);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [fetchPoint] = useLazyQuery(POINT_GUIDENUMBER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const p = d.getPoinGuideNumber;
      console.log('point: ', p);
      if (!p) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: t("No se encontró el número de guia"),
        });
        return;
      }

      // setPoint(p);
      history.push("/cargoTracking", {
        point: p
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  return (
    <>
    <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Card className="login-container" style={{ width: "370px" }}>
      <h4 style={{ color: "white" }}>{t("Seguimiento de Cargas")}</h4>

      <ReduxTextField
        label={t("Número Guía")}
        id={!isCargoTrackingPage && "cargoTrackingNumber"}
        //placeholder={placeholder}
        fullWidth
        value={guideNumber}
        margin="normal"
        style={{ color: '#fff' }}
        onChange={(evt) => setGuideNumber(evt.target.value)}
        {...cargoTrackingStyle}
      />

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={handleChangeRecaptcha}
      />

      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => {
          // if (!isCaptchaValid) return;
            fetchPoint({
              variables: {
                GuideNumber: guideNumber,
              },
            });
            // history.push("/cargoTracking", {
            //   guideNumber: guideNumber,
            //   isCaptchaValid: true
            //   // isCaptchaValid: isCaptchaValid,
            // });
        }}
      >
        {t("Buscar Número de Guía")}
      </Button>
    </Card>
    </>
  );
};

export default Form;
