import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Badge, Button, Grid, IconButton } from "@material-ui/core";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Select from "react-select";
import FilterImg from "../../public/filter.png";
import FilterOffImg from "../../public/filter_off.png";
import MailOpen from "../../public/mailOpen.png";
import VolumeOff from "../../public/volumeOff.png";
import VolumeOn from "../../public/volumeOn.png";
import ReactSelectStyle from "../../utils/reactSelectStyle";
import { hasPermission } from "../../utils/generalUtils";
import { useLazyQuery, useQuery } from "@apollo/client";
import GET_EVENTS_ALARM_QUERY from "../../graphql/queries/getEventsAlarmQuery";
import TYPEALARM_NOTIFICATION_QUERY from "../../graphql/queries/typeAlarmNotificationQuery";
import { useTranslation } from "../../lang";
import { NOTIFICATION_ITEM_COLORS } from "../../Theme";
import GenericSelector from "../Selectors/GenericSelector";

const theme = createMuiTheme({});

const useStyles = makeStyles(() => ({
  customBadgeAlert: {
    backgroundColor: "rgba(181, 9, 9, 1)",
    color: "white",
  },
  customBadgeMaintence: {
    backgroundColor: "rgba(52, 133, 0, 1)",
    color: "white",
  },
  customBadgeReport: {
    backgroundColor: "rgba(43, 108, 219, 1)",
    color: "white",
  },
  seeReadFilter: {
    padding: "0 0 0 15px",
  },
}));

const Filter = ({
  typeNotifications,
  getTypeNotifications,
  typeSel,
  setTypeSel,
  typeAlarm,
  setTypeAlarm,
  setEvent,
  soundActivated,
  setSoundActivated,
  cantUnACKHardware,
  cantUnACKSoftware,
  cantUnACKWithoutTransmision,
  readNotifications,
  selectedNotifications,
  clearFilters,
  loggedUser,
  selectAllNotifications,
  unSelectAllNotifications,
  notifications,
  eventSel,
  readNotificationsMassively,
  handleChangeExtraFiltersVisible,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [showFilters, setShowFilters] = useState(false);
  const [events, setEvents] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    getTypeNotifications();
  }, []);

  const options = [
    { value: -1, label: t("Todos") },
    ...typeNotifications.map((t) => ({
      value: t.id,
      label: t.description,
    })),
  ];

  const alarmTypeOptions = [
    { value: -1, label: t("Todas") },
    { value: "HARDWARE", label: t("Hardware") },
    { value: "SOFTWARE", label: t("Software") },
    { value: "WITHOUTTRANSMISION", label: t("Sin transmisión") },
  ];

  const defaultValue = options.filter((x) => x.value === typeSel);

  const columnEvents = [
    {
      dataField: "event",
      text: t("Evento"),
      sort: true,
    },
    {
      dataField: "typeAlarm",
      text: t("typeAlarm"),
      sort: true,
    },
  ];

  useQuery(TYPEALARM_NOTIFICATION_QUERY, {
    onCompleted(d) {
      if (d.typeAlarmNotification) setEvents(d.typeAlarmNotification);
    },
  });

  return (
    <>
      <Grid container className="notificationFilterContainer">
        <Grid item xs={3}>
          <IconButton
            id="iconFilterNotif"
            onClick={() => {
              setShowFilters(!showFilters);
              handleChangeExtraFiltersVisible();
            }}
          >
            <img
              src={FilterImg}
              className="iconNotificationFilters"
              alt="filter notifications"
            />
          </IconButton>
          <label htmlFor="iconFilterNotif" className="labelFilterNotification">
            {t("Filtrar notificaciones")}
          </label>
        </Grid>
        <Grid item xs={4}>
          {hasPermission("LeerNotificaciones", loggedUser.roles) && (
            <>
              <IconButton
                id="readNotifIcon"
                disabled={!selectedNotifications}
                style={
                  !selectedNotifications || selectedNotifications.length <= 0
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (allSelected) {
                    readNotificationsMassively();
                  } else if (
                    Array.isArray(selectedNotifications) &&
                    selectedNotifications.length > 0
                  ) {
                    const notificationsToRead = selectedNotifications.map((n) =>
                      parseInt(n, 10)
                    );
                  }
                  setAllSelected(false);
                }}
              >
                <img
                  src={MailOpen}
                  className="iconNotificationFilters"
                  alt="read notifications"
                />
              </IconButton>
              <label
                htmlFor="readNotifIcon"
                className="labelFilterNotification"
                disabled={!selectedNotifications}
                style={
                  !selectedNotifications || selectedNotifications.length <= 0
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                {t("Asentir Alarmas")}
              </label>
              <Checkbox
                checked={
                  // allSelected ||
                  notifications.selectedNotifications.length ===
                  notifications.notificationsPaginated.length
                }
                onChange={() => {
                  if (
                    // allSelected ||
                    notifications.selectedNotifications.length ===
                    notifications.notificationsPaginated.length
                  ) {
                    setAllSelected(false);
                    unSelectAllNotifications();
                  } else {
                    setAllSelected(true);
                    selectAllNotifications();
                  }
                }}
                title={t("Seleccionar Todas")}
                color="primary"
              />
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <IconButton id="soundIcon" onClick={() => setSoundActivated()}>
            <img
              src={soundActivated ? VolumeOn : VolumeOff}
              alt="alarms sound"
              className="iconNotificationFilters"
              title={
                t("Sonido") +
                " " +
                (soundActivated ? t("Activado") : t("Desactivado"))
              }
            />
          </IconButton>
          {/* <label htmlFor="soundIcon" className="labelFilterNotification">
            {soundActivated ? t("Activado") : t("Desactivado")}
          </label> */}
        </Grid>
        <Grid item xs={3}>
          <ThemeProvider theme={theme}>
            <Badge
              badgeContent={cantUnACKHardware}
              classes={{ badge: classes.customBadgeAlert }}
              max={99999}
              title={t("Hardware")}
            >
              <IconButton
                onClick={() => {
                  // setTypeSel(1);
                  setTypeAlarm("HARDWARE");
                }}
              >
                <NotificationsIcon
                  style={{
                    color:
                      typeAlarm === "HARDWARE"
                        ? NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL_SELECTED
                        : NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL,
                  }}
                />
              </IconButton>
            </Badge>
            <Badge
              badgeContent={cantUnACKSoftware}
              classes={{ badge: classes.customBadgeMaintence }}
              max={9999}
              title={t("Software")}
            >
              {/* 6 */}
              <IconButton
                onClick={() => {
                  // setTypeSel(1);
                  setTypeAlarm("SOFTWARE");
                }}
              >
                <NotificationsIcon
                  style={{
                    color:
                      typeAlarm === "SOFTWARE"
                        ? NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL_SELECTED
                        : NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL,
                  }}
                />
              </IconButton>
            </Badge>
            <Badge
              badgeContent={cantUnACKWithoutTransmision}
              classes={{ badge: classes.customBadgeReport }}
              max={9999}
              title={t("Sin transmisión")}
            >
              {/* 3 */}
              <IconButton
                onClick={() => {
                  // setTypeSel(1);
                  setTypeAlarm("WITHOUTTRANSMISION");
                }}
              >
                <NotificationsIcon
                  style={{
                    color:
                      typeAlarm === "WITHOUTTRANSMISION"
                        ? NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL_SELECTED
                        : NOTIFICATION_ITEM_COLORS.NOTIFICATION_BELL,
                  }}
                />
              </IconButton>
            </Badge>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid
        container
        className="notificationFilterContainer"
        style={{ display: showFilters ? "flex" : "none" }}
      >
        <Grid item xs={3} style={{ paddingRight: "10px" }}>
          <Select
            placeholder={t("Tipo de notificación")}
            options={options}
            onChange={(type) => {
              setTypeSel(type.value);
            }}
            value={
              typeAlarm !== -1
                ? { value: 1, label: t("Alarma") }
                : defaultValue[0]
            }
            styles={ReactSelectStyle}
          />
        </Grid>

        <Grid item xs={3}>
          {(typeSel === 1 || typeAlarm !== -1) && (
            <Select
              placeholder={t("Tipo de Alarma")}
              options={alarmTypeOptions}
              onChange={(type) => {
                if (typeSel === 10 && typeAlarm === -1)
                  localStorage.removeItem(`event_${loggedUser.id}`);
                setTypeAlarm(type.value);
              }}
              styles={ReactSelectStyle}
              value={alarmTypeOptions.filter(
                (option) => option.value === typeAlarm
              )}
            />
          )}
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: "10px", marginTop: -27 }}>
          {(typeSel === 1 || typeAlarm !== -1) && (
            <GenericSelector
              callbackOptionSelected={(event) =>
                // setFieldValue("typeMobileId", typeMobile?.value)
                setEvent(event.map((e) => e.value))
              }
              isMulti
              columns={columnEvents}
              keyField="id"
              labelField="event"
              title={t("Alarma")}
              data={events}
              defaultValue={eventSel}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              clearFilters();
            }}
            title="Clear filters"
          >
            {/* {t("Borrar filtros")} */}
            <img
              src={FilterOffImg}
              className="iconNotificationFilters"
              alt="Clear filters"
            />
          </Button>
          <IconButton
            onClick={() => {
              setShowFilters(false);
              handleChangeExtraFiltersVisible();
            }}
          >
            <ClearIcon color="#4A5568" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

const _mapStateToProps = ({ notifications }) => {
  return {
    notifications,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Filter);
