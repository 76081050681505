import React, { useState } from "react";
import { connect } from "react-redux";

import ListItemText from "@material-ui/core/ListItemText";
import { es } from "date-fns/locale";
import FlexRow from "../Base/FlexRow";
import { formatDistanceToNow, format } from "date-fns";

import GetAppIcon from "@material-ui/icons/GetApp";
import MapIcon from "@material-ui/icons/Map";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import BuildIcon from "@material-ui/icons/Build";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  GetDateFormat,
  hasPermission,
  truncateString,
} from "../../utils/generalUtils";
import MiniMap from "../Base/MiniMap";
import { useTranslation } from "../../lang";
import { NOTIFICATION_ITEM_COLORS } from "./../../Theme";

const NotificationItem = ({
  item,
  ackAlarms,
  selectNotification,
  unselectNotification,
  selectedNotifications,
  loggedUser,
  user,
}) => {
  const [t] = useTranslation();
  const [isMiniMapOpen, setIsMiniMapOpen] = useState(false);
  const closeMiniMap = () => setIsMiniMapOpen(false);
  const getNotificationDate = (date) => {
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    let formatedDate;

    if (isToday) {
      formatedDate = format(date, GetDateFormat(user.language));
    } else {
      formatedDate = formatDistanceToNow(date, {
        addSuffix: true,
        includeSeconds: true,
        locale: es,
      });
    }

    return formatedDate;
  };

  const getIcon = (item) => {
    let icon = (
      <div
        style={{
          display: "block",
          margin: "auto",
        }}
      />
    );

    switch (item.typeNotification.id) {
      case 1:
        icon = (
          <NotificationsIcon
            style={{
              display: "block",
              margin: "auto",
              color: item.datereaded ? "gray" : "white",
            }}
          />
        );
        break;
      case 3:
        icon = (
          <AssignmentIcon
            style={{
              display: "block",
              margin: "auto",
              color: item.datereaded ? "gray" : "white",
            }}
          />
        );
        break;
      case 6:
        icon = (
          <BuildIcon
            style={{
              display: "block",
              margin: "auto",
              color: item.datereaded ? "gray" : "white",
            }}
          />
        );
        break;
      case 10:
        icon = (
          <SettingsBrightnessIcon
            style={{
              display: "block",
              margin: "auto",
              color: item.datereaded ? "gray" : "white",
            }}
          />
        );
        break;
      default:
        break;
    }

    return icon;
  };

  const getBorderColor = () => {
    let color;
    switch (item.typeNotificationid) {
      case 1:
        color = item.datereaded ? "rgba(181, 9, 9, 0.6)" : "rgba(181, 9, 9, 1)";
        break;
      case 3:
        color = item.datereaded
          ? "rgba(43, 108, 219, 0.6)"
          : "rgba(43, 108, 219, 1)";
        break;
      case 6:
        color = item.datereaded
          ? "rgba(52, 133, 0, 0.6)"
          : "rgba(52, 133, 0, 1)";
        break;
      case 10:
        color = item.datereaded
          ? "rgba(219, 135, 0, 0.6)"
          : "rgba(219, 135, 0, 1)";
        break;
      default:
        color = "rgba(181, 9, 9, 1)";
        break;
    }

    return color;
  };

  const getBackgroundColor = (event) => {
    const sanitizedEvent = event.toLowerCase();

    switch (sanitizedEvent) {
      // Verde oscuro
      case "ignition on":
      case "puerta cerrada":
      case "encendido motor":
      case "solicitud de posición":
      case "conexión batería":
        return NOTIFICATION_ITEM_COLORS.GREEN_DARK;
      // Verde claro
      case "conexión":
      case "en servicio":
        return NOTIFICATION_ITEM_COLORS.GREEN_LIGHT;
      // Marrón verdoso
      case "ignition off":
      case "apagado de motor":
        return NOTIFICATION_ITEM_COLORS.BROWN_GREEN;
      // Bordó -- antes rosado
      case "exceso de velocidad":
      case "exceso de velocidad 40 km/h":
      case "no transmite":
      case "puerta abierta":
      case "parada":
      case "sin transmisión":
        return NOTIFICATION_ITEM_COLORS.RED_DARK;
      // Anaranjado
      case "tiempo parada excedida":
      case "tiempo parada":
      case "tiempo parada excedida log":
      case "tiempo parada excedida orbcomm":
      case "parqueo":
      case "parada de 15 minutos":
      case "parada de 30 minutos":
      case "parada de 1 hora":
      case "parada de 40 minutos":
      case "desconexion de antena":
        return NOTIFICATION_ITEM_COLORS.ORANGE;
      // Rojo
      case "botón de pánico":
      case "fuera de servicio":
        return NOTIFICATION_ITEM_COLORS.RED;
      // Gris
      case "eventos leidos":
        return NOTIFICATION_ITEM_COLORS.GRAY;
      // Amarillo
      case "desconexión":
      case "apagado de bateria":
        return NOTIFICATION_ITEM_COLORS.YELLOW;
      // Entradas y Salidas
      case sanitizedEvent.startsWith("entrada"):
      case sanitizedEvent.startsWith("salida"):
        return NOTIFICATION_ITEM_COLORS.IN_OUT;
      default:
        return NOTIFICATION_ITEM_COLORS.DEFAULT;
    }
  };

  const isNotifSelected = selectedNotifications.includes(item.id);

  const buildNotificationTitle = (item) => {
    let title = `${item.source}`;
    if (item && item.point && item.point.nCli) {
      title = `${item.source} (${item.point.nCli})`;
    }

    return title;
  };

  const buildNotificationBody = () => {
    return item.event + " - " + getNotificationDate(new Date(item.date));
  };

  const buildNotificationPoint = () => {
    let point = " ";
    if (item && item.point && item.point.dir) {
      point = `${item.point?.dir}`;
    }
    return point;
  };

  const notificationTitle = buildNotificationTitle(item);
  const notificationBody = buildNotificationBody(item);
  const notificationPoint = buildNotificationPoint(item);

  return (
    <div
      style={{
        backgroundColor: isNotifSelected
          ? NOTIFICATION_ITEM_COLORS.NOTIFICATION_ALERT_SELECTED
          : "inherit",
      }}
      onClick={() => {
        if (isNotifSelected) {
          unselectNotification(item.id);
        } else {
          selectNotification(item.id);
        }
      }}
    >
      {/* <div style={getStyle(item)}>&nbsp;</div> */}
      <div
        style={{
          display: "inline-block",
          width: "100%",
          borderLeft: `5px solid ${getBorderColor()}`,
        }}
      >
        <FlexRow
          bottomDivider
          expansion="full"
          _classNames={"notification-content__row pad-hor-4 pad-vert-1"}
          //handleClick={() => selectedItems.includes(n.id) ? unselectNotification(n.id) : selItem(n.id)}
        >
          <div
            style={{
              position: "relative",
              width: "10%",
              backgroundColor: getBackgroundColor(item.event),
              borderRadius: "10%",
              alignItems: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
              marginRight: "10px",
            }}
          >
            {getIcon(item)}
          </div>
          <FlexRow
            direction="column"
            //_classNames="notification-content"
            _classNames="notification-description"
          >
            <ListItemText
              className="notification-content-item notification-content__title smallFont readedFont"
              primary={notificationTitle}
            />
            <ListItemText
              className={`notification-content-item notification-content__description ${
                item.datereaded && "readedFont"
              }`}
              primary={
                truncateString(item.event, 32) +
                " - " +
                getNotificationDate(new Date(item.date))
              }
              title={item.event}
            />

            <ListItemText
              // className="notification-content-item notification-content__title smallFont"
              className="notification-content-item notification-content__title smallFont readedFont"
              primary={notificationPoint}
              //primary={`Lon. : -64.123789`}
            />
            {/* <ListItemText
              className="notification-content-item notification-content__time smallFont readedFont"
              //primary={formatDistanceToNow(new Date(item.point.fGPS), { addSuffix: true, includeSeconds: true, locale: es })}
              primary={getNotificationDate(new Date(item.date))}
            /> */}
            {/* </div> */}
          </FlexRow>

          <FlexRow direction="column" _classNames="alarmsData readedFont">
            {item.typeNotification.id === 1 && (
              <>
                <ListItemText
                  className="notification-content-item notification-content__title smallFont"
                  primary={`${t("Vel")} : ${item.point?.vel}`}
                  //primary={`Vel. : 70Km/h`}
                />
                <ListItemText
                  className="notification-content-item notification-content__title smallFont"
                  primary={`Lat/Lon: ${item.point?.lat} ${item.point?.lon}`}

                  //primary={`Lat. : -32.45671`}
                />

                {/* <ListItemText
              className="notification-content-item notification-content__title smallFont"
              primary={`Temp. : 20°C`}
            />*/}
              </>
            )}
          </FlexRow>
          <div className="notificationButtonContainer">
            <FlexRow direction="column">
              {item.typeNotification.id === 2 && (
                <ListItemText primary={<GetAppIcon />} />
              )}
            </FlexRow>

            {item.typeNotification.id === 1 && (
              <>
                {hasPermission("AsentirAlarmas", loggedUser.roles) && (
                  <ListItemText
                    primary={
                      <button
                        type="button"
                        title={t("Asentir")}
                        onClick={() => ackAlarms([item.id])}
                        className="btnActionNotification"
                      >
                        <CheckCircleOutlineIcon
                          style={{ color: "rgb(160, 168, 197)" }}
                        />
                      </button>
                    }
                  />
                )}
                <ListItemText
                  primary={
                    <button
                      type="button"
                      title={t("Ver en mapa")}
                      className="btnActionNotification"
                      // onClick={() => setMarkerSelected(item.point?.mid)}
                      onClick={() => setIsMiniMapOpen(true)}
                    >
                      <MapIcon style={{ color: "rgb(160, 168, 197)" }} />
                    </button>
                  }
                />
                <MiniMap
                  isOpen={isMiniMapOpen}
                  closeModal={closeMiniMap}
                  lat={item.point.lat}
                  lon={item.point.lon}
                  tooltipText={`${item?.event} - ${item?.point.fGPS}`}
                  title={t("notification_item_posicion_alarma", {
                    source: item.source,
                    client: item?.point?.nCli,
                    event: item?.event,
                  })}
                  // title={`Posición de Alarma - ${item.source} - Cliente: ${item?.point?.nCli} - Evento: ${item?.event}`}
                  // tooltipText={`${item.point.evtDesc} Fecha GPS: ${item.point.fGPS}`}
                  point={item.point}
                />
              </>
            )}
          </div>
          {/* <div
        style={{ width: '20px', height: '20px', borderRadius: '50%'}}
        className={`notification-icon-status--${item.datereaded ? 'read' : 'new'}`}
      />*/}
        </FlexRow>
      </div>
    </div>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(NotificationItem);
