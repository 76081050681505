import React from "react";

// TODO: lazy load this?
import GeneralInfoExtra from "./GeneralInfoExtra";
import RevisionsExtra from "./RevisionsExtra";

const availableExtraContent = {
  GeneralInfoExtra,
  RevisionsExtra,
};

const ExtraContent = ({ panelAuxComponent, ...props }) => {
  console.log('Panel Aux: ', panelAuxComponent);
  let component = null;
  if (typeof panelAuxComponent === "string") {
    const AvailableExtraContent = availableExtraContent[panelAuxComponent];

    component = <AvailableExtraContent {...props} />;
  }

  return component;
};

export default ExtraContent;
