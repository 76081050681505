export const BASE_COLORS = {
  // Shades
  WHITE: "#fff",
  GRAY100: "#f8f9fa",
  GRAY200: "#e9ecef",
  GRAY300: "#dee2e6",
  GRAY400: "#ced4da",
  GRAY500: "#adb5bd",
  GRAY600: "#6c757d",
  GRAY700: "#495057",
  GRAY800: "#343a40",
  GRAY900: "#212529",
  GRAY909090: "#909090",
  BLACK: "#000",

  // Colors
  BLUE: "#0d6efd",
  DARK_BLUE: "#1c3663",
  INDIGO: "#6610f2",
  PURPLE: "#6f42c1",
  PINK: "#d63384",
  RED: "#dc3545",
  ORANGE: "#fd7e14",
  YELLOW: "#ffc107",
  GREEN: "#06b100",
  TEAL: "#20c997",
  CYAN: "#0dcaf0",
  BRAND: "#0d6efd",
  BRAND_CONTRAST: "#fff",
  TRANSPARENT: "transparent",
};

export const SEMANTIC_COLORS = {
  PRIMARY: BASE_COLORS.BLUE,
  PRIMARY_CONTRAST: BASE_COLORS.WHITE,
  SECONDARY: BASE_COLORS.DARK_BLUE,
  SUCCESS: BASE_COLORS.GREEN,
  INFO: BASE_COLORS.CYAN,
  WARNING: BASE_COLORS.YELLOW,
  DANGER: BASE_COLORS.RED,
  LIGHT: BASE_COLORS.GRAY100,
  DARK: BASE_COLORS.GRAY900,
  BRAND_SELECTED: BASE_COLORS.BRAND,
};

export const APP_COLORS = {
  APP_BASE: "#2F2F2F",
  APP_BASE_SECONDARY: "#2F2F2F",
  APP_BASE_TEXT_COLOR: BASE_COLORS.GRAY100,
};

export const INPUT_COLORS = {
  BORDER: APP_COLORS.APP_BASE_SECONDARY,
  BORDER_HOVER: BASE_COLORS.WHITE,
  BACKGROUND: APP_COLORS.APP_BASE,
  TEXT: BASE_COLORS.GRAY909090,
  PLACEHOLDER: BASE_COLORS.GRAY909090,
};

export const TABLE_COLORS = {
  ROW_SELECTED: BASE_COLORS.DARK_BLUE,
};

// From NotificationItem.jsx
export const NOTIFICATION_ITEM_COLORS = {
  GREEN_DARK: "#06750F",
  GREEN_LIGHT: "#00FF00",
  BROWN_GREEN: "#8F7B0A",
  RED: "#FF0000",
  RED_DARK: "#AA0000",
  ORANGE: "#F1992F",
  GRAY: "#9A9A9A",
  YELLOW: "#FFFF00",
  IN_OUT: "#8F7B0A",
  DEFAULT: "#333333",

  NOTIFICATION_BELL: "#4a6578",
  NOTIFICATION_BELL_SELECTED: BASE_COLORS.WHITE,
  NOTIFICATION_ALERT_SELECTED: BASE_COLORS.DARK_BLUE,
};
