import React, { useEffect, useState } from "react";

import fontawesome from "@fortawesome/fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./GroupTree.css";
import { useTranslation } from "../../lang";

fontawesome.library.add(faCheckSquare);

const GroupTree = ({
  groups,
  customerSelected,
  checkCallback,
  nodesChecked,
  isOwner
}) => {
  const [t] = useTranslation();
  const [nodes, setNodes] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nodesFiltered, setNodesFiltered] = useState([]);

  const listToTree = (list) => {
    const map = {};
    let node,
      roots = [],
      i;

    const aux = [
      ...list.map((l) => ({
        ...l,
        children: [],
      })),
    ];

    for (i = 0; i < aux.length; i += 1) {
      map[aux[i].id] = i; // initialize the map
      //aux[i].children = []; // initialize the children
    }

    for (i = 0; i < aux.length; i += 1) {
      node = aux[i];
      if (
        node.parentGroupId &&
        map[node.parentGroupId] &&
        aux[map[node.parentGroupId]]
      ) {
        // if you have dangling branches check that map[node.parentId] exists

        aux[map[node.parentGroupId]].children.push({
          label: node.name,
          value: node.id,
          title: node.name,
          children: node.children.length > 0 ? node.children : undefined,
        });
      } else {
        roots.push({
          label: node.name,
          value: node.id,
          title: node.name,
          children: node.children.length > 0 ? node.children : undefined,
        });
      }
    }
    return roots;
  };

  useEffect(() => {
    if (groups.length > 0 && customerSelected.length > 0) {
      const allCustomers = customerSelected.some((c) => c.value === -1);
      const thisGroups = allCustomers
        ? groups
        : groups.filter((x) =>
            !isOwner || customerSelected.map((c) => c.value).includes(x.customerId)
          );
      const tree = listToTree([...thisGroups]);

      setNodes(tree);
      setNodesFiltered(tree);
    }
  }, [groups, customerSelected]);

  //const [groupsSel, setGroupsSel] = useState([nodesChecked]);
  const [groupsExpanded, setGroupsExpanded] = useState();

  const handleOnCheck = (selection) => {
    //const aux = selection.map(s => Number.isNaN(Number.parseInt(s)) ? s : Number.parseInt(s));
    const newSel = selection.map((s) => parseInt(s, 10));

    checkCallback && checkCallback(newSel);
  };

  const handleOnExpand = (selection) => {
    setGroupsExpanded(selection);
  };

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
        -1 ||
      children.length
    ) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }

    return filtered;
  };

  const handleFilterChange = (evt) => {
    setFilterText(evt.target.value);
    // Reset nodes back to unfiltered state
    if (!evt.target.value) {
      setNodesFiltered(nodes);
      return;
    }

    const groupsFiltered = nodes.reduce(filterNodes, []);
    setNodesFiltered(groupsFiltered);
  };

  const isSomeExpanded =
    Array.isArray(groupsExpanded) && groupsExpanded.length > 0;

  return (
    <>
      {
        <div className="searchContainer">
          <TextField
            placeholder={t("Buscar_")}
            type="text"
            variant="outlined"
            size="small"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              width: "100%",
            }}
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      }
      <div
        className="treeContainer"
        style={{
          display: isSomeExpanded ? "contents" : "inline-block",
          marginTop: isSomeExpanded ? "20px" : "0",
        }}
      >
        <CheckboxTree
          nodes={nodesFiltered}
          //nodes={nodes}
          checked={nodesChecked.map((nc) => nc.toString())}
          expanded={groupsExpanded}
          onCheck={handleOnCheck}
          onExpand={handleOnExpand}
          checkModel={filterText ? "leaf" : "all"}
          showNodeIcon={false}
          //icons="fa5"
          icons={{
            halfCheck: <span className="rct-icon rct-icon-half-check" />,
          }}
          showNodeTitles={true}
        />
      </div>
    </>
  );
};

export default GroupTree;
