export const secondsToTime = (s) => {
  if (s) {
    var fm = [
      Math.floor(s / 60 / 60 / 24), // DAYS
      Math.floor(s / 60 / 60) % 24, // HOURS
      Math.floor(s / 60) % 60, // MINUTES
      s % 60, // SECONDS
    ];
    return fm
      .map((v, i) => {
        return (v < 10 ? "0" : "") + v;
      })
      .join(":");
  }

  return "00:00:00:00";
};

export const roundNumber = (number, decimals) => {
  return number ? parseFloat(number).toFixed(decimals) : "";
};

export const getTaxIdentificator = () => {
  let taxIdentificator;

  switch (process.env.REACT_APP_CUSTOMER) {
    case "Satellital":
      taxIdentificator = "RUC/DNI";
      break;
    case "Reaxium":
      taxIdentificator = "TIN";
      break;
    default:
      taxIdentificator = "CUIT";
      break;
  }

  return taxIdentificator;
};

export const useDarkMode = () => {
  let darkMode;

  switch (process.env.REACT_APP_CUSTOMER) {
    case "Satellital":
      darkMode = true;
      break;
    case "Reaxium":
      darkMode = false;
      break;
    default:
      darkMode = false;
      break;
  }

  return darkMode;
};

export const GeometryTypes = {
  POLYGON: "polygon",
  LINE: "polyline",
  RECTANGLE: "rectangle",
  CIRCLE: "circle",
  MARKER: "marker",
  CIRCLEMARKER: "circlemarker",
};

export const downloadExcel = (string64, fileName) => {
  const fileType =
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
  const downloadLink = document.createElement("a");
  downloadLink.download = `${fileName}.xlsx`;
  downloadLink.href = `${fileType}${string64}`;
  downloadLink.click();
};

export const downloadPDF = (string64, fileName) => {
  const fileType = "data:application/pdf;base64,";
  const downloadLink = document.createElement("a");
  downloadLink.download = `${fileName}.pdf`;
  downloadLink.href = `${fileType}${string64}`;
  downloadLink.click();
};

export const downloadKML = (string64, fileName) => {
  const kmlBlob = new Blob([string64], {
    type: "data:application/vnd.google-earth.kml+xml;base64,",
  });
  const downloadURL = URL.createObjectURL(kmlBlob);
  const downloadLink = document.createElement("a");
  downloadLink.download = `${fileName}.kml`;
  downloadLink.href = downloadURL;
  downloadLink.click();
};

export const hasPermission = (permissionName, roleList = []) => {
  if (!permissionName || !roleList || roleList.length === 0) {
    return false;
  }

  return roleList.some((role) => {
    return role.permission.some((p) => p.name.trim() === permissionName.trim());
  });
};

export const GetType = (type) => {
  if (type === 0) return "success";
  else if (type === 1) return "low-connection";
  else if (type === 2) return "danger";
  else if (type === 3) return "alert";
  else if (type === 4) return "inactive";
  else if (type === 5) return "person";
  else return "generic";
};

export const GetDateFormat = (lang, short = false) => {
  if (lang === "US" || lang === "ES_US")
    return short ? "MM-dd-yyyy" : "MM-dd-yyyy HH:mm:ss";
  else if (lang === "ES") return short ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm:ss";
  else return short ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm:ss";
};

export const GetDateFormatDateSelector = (lang, short = false) => {
  if (lang === "US" || lang === "ES_US")
    return short ? "MM-DD-yyyy" : "MM-DD-yyyy HH:mm:ss";
  else if (lang === "ES") return short ? "DD/MM/yyyy" : "DD/MM/yyyy HH:mm:ss";
  else return short ? "DD/MM/yyyy" : "DD/MM/yyyy HH:mm:ss";
};

export const truncateString = (input, length) =>
  input.length > length ? `${input.substring(0, length)}...` : input;
