import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";

import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";

import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

import Dropdown from "../Base/Dropdown";

import Customerselect from "./CustomerSelect";

import { getGroupsByUser } from "../../redux/groups/actions";

import "./GroupsButton.scss";

import {
  setSelectedGroups,
  setSelectedCustomer,
} from "../../redux/groups/actions";

import SimpleCustomerQuery from "../../graphql/queries/simpleCustomerQuery";

import GroupTree from "./GroupTree";

import { useTranslation } from "../../lang";

const GroupsButton = ({
  groups,
  getGroupsByUser,
  setSelectedGroups,
  setSelectedCustomer,
  user,
}) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [treeGroups, setTreeGroups] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  // const [searchText, setSearchText] = useState();
  const [firstBranchs, setFirstBranchs] = useState([]);
  const [customerSelected, setCustomerSelected] = useState([]);
  const [nodesChecked, setNodesChecked] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([]);

  const isOwner = useMemo(() => user.roles.some((r) => r.isOwner), [user]);

  const {} = useQuery(SimpleCustomerQuery, {
    variables: {
      paginate: false,
      sort: {
        name: "asc",
      },
    },
    fetchPolicy: "network-only",
    onCompleted(d) {
      const allOption = { label: t("Todos"), value: -1 };
      const { items } = d.customers;
      setCustomers(items);
      setCustomers(items.length === 1 ? items : [allOption, ...items]);
    },
    onError(err) {
      const user = JSON.parse(localStorage.getItem("loggedUser"));
      const ownCustomer = [
        { label: user.customer.name, value: user.customer.id },
      ];
      setCustomers(ownCustomer);
      handleChangeCustomer(ownCustomer, true);
      //setSelectedCustomer(ownCustomer);
    },
  });

  // Translate "All/Todos" option when user changes language
  useEffect(() => {
    if (customers && customers.length > 0) {
      const newCustomers = [...customers];
      newCustomers.shift();

      setCustomers(
        customers.length === 1
          ? customers
          : [{ label: t("Todos"), value: -1 }, ...newCustomers]
      );
    }
  }, [user.language]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    if (user) {
      getGroupsByUser(user.id || user.data.id);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(groups) && groups.length > 0) {
      const user = JSON.parse(localStorage.getItem("loggedUser"));
      const customersStr = localStorage.getItem(`customerSelected_${user.id}`);
      let customersSel;
      if (isOwner) {
        customersSel = customersStr
          ? JSON.parse(customersStr)
          : [{ label: t("Todos"), value: -1 }];
      } else {
        customersSel = [{ label: user.customer.name, value: user.customer.id }];
      }
      setDefaultCustomer(customersSel);

      handleChangeCustomer(customersSel, true);
    }
  }, [groups]);

  useEffect(() => {
    // console.log("Nodes checked: ", nodesChecked);
    setSelectedGroups(nodesChecked);
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    if (Array.isArray(nodesChecked) && nodesChecked.length > 0) {
      localStorage.setItem(`groupsSelected_${user.id}`, nodesChecked.join(","));
    }
  }, [nodesChecked]);

  const handleChangeCustomer = (values, firstLoad) => {
    setCustomerSelected(values);
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    localStorage.setItem(`customerSelected_${user.id}`, JSON.stringify(values));

    setSelectedCustomer(values);

    if (values) {
      const allCustomers = values.some((x) => x.value === -1);
      let groupsOfThisCustomer = [];

      if (allCustomers || !isOwner) {
        localStorage.setItem(
          `groupsSelected_${user.id}`,
          groups.map((g) => g.id).join(",")
        );
        groupsOfThisCustomer = groups;
      } else {
        groupsOfThisCustomer = groups.filter((g) =>
          values.some((v) => v.value === g.customerId)
        );
      }

      let firstBranchs = [];
      for (let n of values) {
        const firstChildrens = groupsOfThisCustomer.filter(
          (g) => (g.customerId == n.value || allCustomers || !isOwner) && !g.parentGroupId
        );
        /*n.children = firstChildrens.map(fc => ({
          label: fc.name,
          value: fc.id
        }));*/
        firstBranchs = [...firstBranchs, ...firstChildrens];
      }
      setTreeGroups(groupsOfThisCustomer);

      let savedGroups = [];
      //const user = JSON.parse(localStorage.getItem("loggedUser"));
      const groupsSel = localStorage.getItem(`groupsSelected_${user.id}`);
      if (firstLoad && groupsSel) {
        groupsSel
          .split(",")
          .forEach((gs) =>
            savedGroups.push(
              Number.isNaN(Number.parseInt(gs)) ? gs : Number.parseInt(gs)
            )
          );
      }

      const savedGroupsOfThisCustomer = savedGroups.filter((x) =>
        groupsOfThisCustomer.map((g) => g.id).includes(x)
      );

      //console.log("Saved groups: ", savedGroups);
      const nodesCh =
        firstLoad && savedGroupsOfThisCustomer.length > 0
          ? savedGroupsOfThisCustomer
          : [
              ...values.map((v) => `C_${v.value}`),
              ...groupsOfThisCustomer.map((g) => g.id),
            ];

      //console.log("Nodes CH: ", nodesCh);
      setNodesChecked(nodesCh);

      const allIds = groupsOfThisCustomer.map((g) => g.id);
      const orphanGroups = groupsOfThisCustomer.filter(
        (x) => x.parentGroupId && !allIds.includes(x.parentGroupId)
      );

      setFirstBranchs([...firstBranchs, ...orphanGroups]);
    } else {
      const user = JSON.parse(localStorage.getItem("loggedUser"));
      localStorage.setItem(`groupsSelected_${user.id}`, "");
      setTreeGroups([]);
      setNodesChecked([]);
    }
  };

  return (
    <Dropdown
      className="dropdown-groups"
      title={t("Grupos")}
      icon={AccountTreeOutlinedIcon}
      header={
        <Grid container className="groupsMenuContainer-header">
          {customers.length > 1 && (
            <div className="selectContainer">
              <Customerselect
                customerOptions={customers}
                handleChangeCustomer={handleChangeCustomer}
                defaultValue={defaultCustomer}
              />
            </div>
          )}

          {customers.length === 1 && (
            <div className="selectContainer">
              <h3 style={{ color: "white" }}>{customers[0].label}</h3>
            </div>
          )}
        </Grid>
      }
      items={[
        {
          item: (
            <Grid container className="groupsMenuContainer">
              {customerSelected && isSearching && (
                <div className="searchResultContainer">
                  <ul className="searchList">
                    {searchResult.map((sr) => (
                      <li key={`SR_${sr.id}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={nodesChecked.includes(sr.id)}
                              onChange={(evt) =>
                                setNodesChecked(
                                  evt.target.checked
                                    ? [...nodesChecked, sr.id]
                                    : nodesChecked.filter((x) => x !== sr.id)
                                )
                              }
                            />
                          }
                          label={sr.name}
                          title={sr.name}
                          className={"breakLabel"}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {customerSelected &&
                !customerSelected.some((x) => x.value === -1) &&
                !isSearching && (
                  <GroupTree
                    groups={groups}
                    customerSelected={customerSelected}
                    nodesChecked={nodesChecked}
                    checkCallback={setNodesChecked}
                    isOwner={isOwner}
                    //checkCallback={handleOnCheck}
                  />
                )}
            </Grid>
          ),
        },
      ]}
    />
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    groups: groups.groups,
    user,
  };
};

const _mapDispatchToProps = {
  getGroupsByUser,
  setSelectedGroups,
  setSelectedCustomer,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(GroupsButton);
