import gql from "graphql-tag";

const CONSOLIDATE_REPORT_QUERY = gql`
  query ConsolidateReport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $resumed: Boolean
    $useIbutton: Boolean
  ) {
    consolidatedReportExportExcel(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      resumed: $resumed
      useIbutton: $useIbutton
    )
  }
`;

export default CONSOLIDATE_REPORT_QUERY;
