import React, { Component, Suspense } from "react";
import { Route, Router } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import Structure from "../components/Base/Structure";

import UserPages from "../pages/UserPages";
import history from "../utils/history";
import ManageRoutes from "./ManageRoutes";
import MapRoutes from "./MapRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicOnlyRoute from "./PublicOnlyRoute";
import ReportRoutes from "./StatisticsRoutes";
import LoginPage from "../pages/LoginPage";

const CargoTrackingPage = React.lazy(() => import("../components/CargoTracking/Page"));
const MultipleTracking = React.lazy(() => import("../components/MultipleTracking/MultipleTracking"));
const BaseMap = React.lazy(() => import("../containers/ConnectedBaseMap"));
const AutoLogin = React.lazy(() => import("../pages/AutoLogin"));
const Forbidden = React.lazy(() => import("../pages/Forbidden"));
// const LoginPage = React.lazy(() => import("../pages/LoginPage"));

/**
 * Each route will have to be explicitly declared with the component you will render
 * on each one.
 * As we are using webpack as our dev server, each one of these routes will also have
 * to be declared in the config file.
 * For production environments some other configuration will be necessary (to be completed later).
 * Let's go to webpack.config.js and find the `vizionAppResponse` object.
 */
export default class PageRoutes extends Component {
  render() {
    /**
     * Manage pages needs to be on top, UserPages is blocking the access
     * to them if they are put below it.
     */

    return (
      <Router history={history}>
        <ToastContainer
          transition={Zoom}
          position="top-right"
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          closeButton={false}
          bodyClassName="toaster-container"
        />

        <UserPages>
          <Route
            path="/"
            exact
            render={(routerParams) => (
              <Suspense fallback={<></>}>
                <PublicOnlyRoute
                  path="/"
                  exact
                  component={LoginPage}
                  {...routerParams}
                />
              </Suspense>
            )}
          />

          <Route path="/cargoTracking" render={props => (
            <Suspense fallback={<></>} >
              <CargoTrackingPage {...props} />
            </Suspense>
          )} />

          <Route path="/autologin/:token" component={AutoLogin} />

          <Route
            path="/login"
            exact
            component={LoginPage}
            render={props => (
              <Suspense fallback={<></>}>
                <LoginPage {...props} />
              </Suspense>
            )}
          />

          <Route
            path="/map"
            render={(routerParams) => <MapRoutes {...routerParams} />}
          />

          <Route
            path="/tracking/:assetId"
            exact
            component={(routerProps) => (
              <Structure>
                <Suspense fallback={<></>}>
                  <PrivateRoute component={BaseMap} {...routerProps} />
                </Suspense>
              </Structure>
            )}
          />
          {/* <Route
            path="/tracking/:assetId"
            component={BaseMap}
            // render={(routerParams) => <MapRoutes {...routerParams} />}
          /> */}

          <Route
            path="/mtracking"
            component={(routerProps) => (
              <Suspense fallback={<></>}><MultipleTracking {...routerProps} /></Suspense>
            )}
          />
        </UserPages>

        {/* <Route
          path="/mtracking"
          render={({ match: { path } }) => <ManageRoutes path={path} />}
        /> */}

        <Route
          path="/manage"
          render={({ match: { path } }) => <ManageRoutes path={path} />}
        />

        <Route
          path="/report"
          render={({ match: { path } }) => <ReportRoutes path={path} />}
        />

        <Route path="/forbidden" render={() => <Suspense fallback={<></>} ><Forbidden /></Suspense>} />
      </Router>
    );
  }
}
