// import { combineReducers } from 'redux';
import includes from "lodash/includes";

import {
  SUCCESS_NOTIFICATION,
  ALERT_NOTIFICATION,
  DANGER_NOTIFICATION,
  INFO_NOTIFICATION,
  CLEAR_NOTIFICATION,
  SELECT_NOTIFICATION,
  UNSELECT_NOTIFICATION,
  SELECTALL_NOTIFICATION,
  GET_TOP_NOTIFICATIONS,
  SET_READ_NOTIFICATIONS,
  SET_NEW_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_TYPE_NOTIFICATIONS,
  NOTIFICATIONS_CHANGE_PARAM,
  NOTIFICATIONS_BYASSET,
  SET_MARKER_SELECTED,
  REMOVE_ALARMS_ACK,
  GET_NOTIFICATIONS_MORE,
  UNSELECTALL_NOTIFICATION,
  NOTIFICATION_PAGINATION_START,
  NOTIFICATION_PAGINATION_END
} from "./actions";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../user/actions";

const NOTIFICATION_TYPES = [
  SUCCESS_NOTIFICATION,
  ALERT_NOTIFICATION,
  DANGER_NOTIFICATION,
  INFO_NOTIFICATION,
];

const SUCCESS_NOTIFICATIONS_TYPES = [LOGIN_SUCCESS, LOGOUT_SUCCESS];
const ERROR_NOTIFICATIONS_TYPES = [LOGIN_FAILURE, LOGOUT_FAILURE];

const initialState = {
  notificationType: "",
  message: "",
  selectedNotifications: [],
  topNotifications: [],
  notificationsPaginated: [],
  typeNotifications: [],
  hasMoreNotifications: false,
  notificationsByAsset: [],
  markerSelected: null,
  hasMoreNotifications: 0,
  loading: false
};

const notificationsReducer = (state = initialState, { type, payload }) => {
  let newState = state;

  if (includes(NOTIFICATION_TYPES, type)) {
    newState = {
      ...state,
      notificationType: type,
      message: payload,
    };
  } else if (includes(SUCCESS_NOTIFICATIONS_TYPES, type)) {
    newState = {
      ...state,
      notificationType: SUCCESS_NOTIFICATION,
      message: payload.message,
    };
  } else if (includes(ERROR_NOTIFICATIONS_TYPES, type)) {
    newState = {
      ...state,
      notificationType: DANGER_NOTIFICATION,
      message: payload.error,
    };
  } else if (type === CLEAR_NOTIFICATION) {
    newState = {
      ...state,
      message: "",
    };
  } else if (type === SELECT_NOTIFICATION) {
    newState = {
      ...state,
      selectedNotifications: [...state.selectedNotifications, payload],
    };
  } else if (type === UNSELECT_NOTIFICATION) {
    newState = {
      ...state,
      selectedNotifications: state.selectedNotifications.filter(
        (x) => x !== payload
      ),
    };
  } else if (type === SELECTALL_NOTIFICATION) {
    newState = {
      ...state,
      // selectedNotifications: state.notificationsPaginated.map((n) => n.id),
      selectedNotifications: state?.topNotifications?.notifications.map(
        (n) => n.id
      ),
    };
  } else if (type === UNSELECTALL_NOTIFICATION) {
    newState = {
      ...state,
      selectedNotifications: [],
    };
  } else if (type === GET_TOP_NOTIFICATIONS) {
    // console.log("payload GET_TOP_NOTIFICATIONS", payload);
    newState = {
      ...state,
      topNotifications: payload,
    };
  } else if (type === GET_NOTIFICATIONS) {
    // console.log("payload GET_NOTIFICATIONS", payload);
    newState = {
      ...state,
      loading: false,
      notificationsPaginated: [
        // ...state.notificationsPaginated,
        ...payload.notifications,
      ],
      topNotifications: payload, // tavo
      totalNotifications: payload.total,
    };
  } else if (type === NOTIFICATION_PAGINATION_START) {
    newState = {
      ...state,
      loading: true,
    };
  } else if (type === NOTIFICATION_PAGINATION_END) {
    newState = {
      ...state,
      loading: false,
    };
  } else if (type === GET_NOTIFICATIONS_MORE) {
    // console.log("payload GET_NOTIFICATIONS_MORE", payload);
    newState = {
      ...state,
      notificationsPaginated: [
        ...state.notificationsPaginated,
        ...payload.notifications,
      ],
      hasMoreNotifications: payload.hasMore,
      totalNotifications: payload.total,
      // topNotifications: payload.notifications, // tavo
    };
  } else if (type === NOTIFICATIONS_CHANGE_PARAM) {
    // console.log("payload NOTIFICATIONS_CHANGE_PARAM", payload);
    newState = {
      ...state,
      notificationsPaginated: payload.notifications,
      hasMoreNotifications: payload.hasMore,
      totalNotifications: payload.total,
      // topNotifications: payload.notifications, // tavo
    };
  } else if (type === SET_READ_NOTIFICATIONS) {
    //Notificaciones de barra superior
    const notificationsUpdated = [...state.topNotifications.notifications].map(
      (n) => ({
        ...n,
        datereaded: payload.includes(parseInt(n.id, 10))
          ? new Date()
          : n.datereaded,
      })
    );

    //Notificaciones de panel lateral
    const notifPaginated = [...state.notificationsPaginated].map((n) => ({
      ...n,
      datereaded: payload.includes(parseInt(n.id, 10))
        ? new Date()
        : n.datereaded,
    }));

    // console.log("Notifs paginated: ", notifPaginated);

    const readedNotifs = state.notificationsPaginated.filter((n) =>
      payload.includes(parseInt(n.id, 10))
    );

    newState = {
      ...state,
      notificationsPaginated: notifPaginated,
      topNotifications: {
        ...state.topNotifications,
        cantUnreadAlarms:
          state.topNotifications.cantUnreadAlarms -
          readedNotifs.filter((x) => x.typeNotificationid === 1).length,
        cantUnreadMaintence:
          state.topNotifications.cantUnreadMaintence -
          readedNotifs.filter((x) => x.typeNotificationid === 6).length,
        cantUnreadReport:
          state.topNotifications.cantUnreadReport -
          readedNotifs.filter((x) => x.typeNotificationid === 3).length,
        cantUnreaded:
          state.topNotifications.cantUnreaded >= payload.length
            ? state.topNotifications.cantUnreaded - payload.length
            : 0,
        notifications: notificationsUpdated,
      },
      selectedNotifications: [],
    };
  } else if (type === GET_TYPE_NOTIFICATIONS) {
    newState = {
      ...state,
      typeNotifications: payload,
    };
  } else if (type === SET_NEW_NOTIFICATION) {
    // console.log("reducer notif", payload);
    /*const limit = parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS);
    let notifications = [];
    if (state.topNotifications.length < limit) {
      notifications = [payload, ...state.topNotifications];
    } else {
      const readed = state.topNotifications.filter((x) => !!x.datereaded);
      const lastReaded =
        Array.isArray(readed) && readed.length > 0
          ? readed.sort((a, b) => b.fGPS - a.fGPS)[0]
          : null;

      if (lastReaded) {
        notifications = [
          payload,
          ...state.topNotifications.filter((x) => x.id !== lastReaded.id),
        ];
      } else {
        notifications = [
          payload,
          ...state.topNotifications.slice(0, state.topNotifications.length - 1),
        ];
      }
    }
    newState = {
      ...state,
      topNotifications: notifications,
    };*/

    newState = state;

    // newState = {
    //   ...state,
    //   notificationsPaginated: payload.notifications,
    //   hasMoreNotifications: payload.hasMore,
    // };
  } else if (type === NOTIFICATIONS_BYASSET) {
    newState = {
      ...state,
      notificationsByAsset: payload,
    };
  } else if (type === SET_MARKER_SELECTED) {
    newState = {
      ...state,
      markerSelected: payload,
    };
  } else if (type === REMOVE_ALARMS_ACK) {
    newState = {
      ...state,
      topNotifications: {
        notifications: state.topNotifications.notifications.filter(
          (x) => !payload.includes(x.id)
        ),
      },
      notificationsPaginated: state.notificationsPaginated.filter(
        (x) => !payload.includes(x.id)
      ),
      selectedNotifications: [],
    };
  }

  return newState;
};

export default notificationsReducer;
