export const isEmpty = value => {
  return value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
};

export const isObject = a => a instanceof Object;

export function memoize(functionParam) {
  // I prefer using Map instead of array like most of the memoize alternative to enforce unique key.
  const cacheMap = new Map();

  // return annonymous closure
  return function(...argsObj) {
    const key = argsObj[0];

    // return the cached version if exists
    if(cacheMap.has(key)) return cacheMap.get(key);

    // Closures allow access to parent scope arguments object if we pass the function as a parameter
    const resultSet = functionParam(...argsObj);

    // Cache what we need
    cacheMap.set(key, resultSet);

    return resultSet;
  }
}

// create an array without the given values
export const without = (arr, ...args) => arr.filter(item => !args.includes(item));