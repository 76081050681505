// eslint-disable react/forbid-prop-types
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import GeneralInfo from "./GeneralInfo";
import Journeys from "./Journeys";
import Notifications from "./Notifications";
import Revisions from "./Revisions";
//import Revisions from "../../RevisionsPanel";
import FuelRefill from "./FuelRefill";

import ArchivoIcon from "../../../public/icons/ArchivoIcon";
import JurneyIcon from "../../../public/icons/JurneyIcon";
import NotificacionIcon from "../../../public/icons/NotificacionIcon";
import MantenimientoIcon from "../../../public/icons/MantenimientoIcon";
import GasPumpIcon from "../../../public/icons/GasPumpIcon";

import { connect } from "react-redux";
import { setFleetStatusPanelInitialStatus } from "../../../redux/lateralPanel/actions";

import "./InfoContent.scss";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// let resetPeriod = false;
const MapMarkerInfoContent = ({
  markerInfo,
  markerJourney,
  markerNotifications,
  markerRevisions,
  toggleActivePanelAux,
  onChangeTab,
  toggleActivePanel,
  toggleFillMarkerInfoAux,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourney,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillGenericPopup,
  updateMarkerJourney,
  fleetStatusPanelInitialStatus,
  setFleetStatusPanelInitialStatus,
  toggleFillMarkerInfo
}) => {
  const [value, setValue] = React.useState(0);
  const [
    journeysSelectedSearchRange,
    setJourneysSelectedSearchRange,
  ] = React.useState();
  const handleChange = (event, newValue) => {
    updateMarkerJourney && updateMarkerJourney({});
    if (value !== newValue) {
      setValue(newValue);
      onChangeTab(newValue);
    }
  };

  useEffect(() => {
    if (
      fleetStatusPanelInitialStatus &&
      fleetStatusPanelInitialStatus.firstTimeAfterRedirect
    ) {
      setValue(1); // Select Routes tab on Fleet Status side panel
      setJourneysSelectedSearchRange(
        fleetStatusPanelInitialStatus.selectedSearchRange
      ); // Select search range on Routes tab
      setFleetStatusPanelInitialStatus({
        firstTimeAfterRedirect: false,
      }); // Disable this initial set up. It prevents it to run again.
    }
  }, []);

  const handleSelectedSearchRange = (option) => {
    setJourneysSelectedSearchRange(option);
  };
  return (
    <div className="expandable-panel__content-wrapper">
      <AppBar className="expandable-panel__tabs" position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={<ArchivoIcon />} {...a11yProps(0)} />
          <Tab label={<JurneyIcon />} {...a11yProps(1)} />
          <Tab label={<NotificacionIcon />} {...a11yProps(2)} />
          <Tab label={<MantenimientoIcon />} {...a11yProps(3)} />
          <Tab label={<GasPumpIcon />} {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <GeneralInfo
        index={0}
        value={value}
        markerInfo={markerInfo}
        toggleActivePanelAux={toggleActivePanelAux}
        toggleActivePanel={toggleActivePanel}
        toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
        toggleFillMarkerInfo={toggleFillMarkerInfo}
      />

      {markerJourney && (
        <Journeys
          index={1}
          value={value}
          markerInfo={markerInfo}
          markerJourney={markerJourney}
          toggleFillMarkerJourney2={toggleFillMarkerJourney2}
          toggleFillMarkerJourney={toggleFillMarkerJourney}
          toggleFillMarkerJourneyPersonalizado={
            toggleFillMarkerJourneyPersonalizado
          }
          toggleFillGenericPopup={toggleFillGenericPopup}
          updateMarkerJourney={updateMarkerJourney}
          dateOptionSelected={journeysSelectedSearchRange}
          onSelectedSearchRange={handleSelectedSearchRange}
          // value2={resetPeriod}
        />
      )}

      <Notifications index={2} value={value} assetid={markerInfo.mid} />

      <Revisions
        index={3}
        value={value}
        markerRevisions={markerRevisions}
        toggleActivePanelAux={toggleActivePanelAux}
        mid={markerInfo?.mid}
        //mid={markerInfo?.mid}
      />

      <FuelRefill index={4} value={value} />
    </div>
  );
};

MapMarkerInfoContent.propTypes = {
  markerInfo: PropTypes.object,
  markerJourney: PropTypes.object,
  markerNotifications: PropTypes.object,
  markerRevisions: PropTypes.object,
  toggleActivePanelAux: PropTypes.func,
  onChangeTab: PropTypes.func,
  toggleActivePanel: PropTypes.func,
  toggleFillMarkerInfoAux: PropTypes.func,
  toggleFillMarkerJourney2: PropTypes.func,
  toggleFillMarkerJourneyPersonalizado: PropTypes.func,
  toggleFillGenericPopup: PropTypes.func,
  toggleFillMarkerInfo: PropTypes.func
};

MapMarkerInfoContent.defaultProps = {
  markerInfo: {},
  markerJourney: {},
  markerNotifications: {},
  markerRevisions: {},
  toggleActivePanelAux: () => {},
  onChangeTab: () => {},
  toggleActivePanel: () => {},
  toggleFillMarkerInfoAux: () => {},
  toggleFillMarkerJourney2: () => {},
  toggleFillMarkerJourneyPersonalizado: () => {},
  toggleFillGenericPopup: () => {},
  toggleFillMarkerInfo: () => {}
};

// export default MapMarkerInfoContent;

const _mapStateToProps = ({ lateralPanel }, {}) => {
  return {
    fleetStatusPanelInitialStatus:
      lateralPanel.newPanelReducer.fleetStatusPanelInitialStatus,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    setFleetStatusPanelInitialStatus: (payload) =>
      dispatch(setFleetStatusPanelInitialStatus(payload)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(MapMarkerInfoContent);
