// eslint-disable react/forbid-prop-types
import React from "react";
import PropTypes from "prop-types";
import FlexRow from "../../Base/FlexRow";
import DivisionJourneys from "../../../public/icons/DivisionJourneys";
import IconAccount from "../../../public/icons/AccountIcon";
import VerticalLine from "../../../public/icons/VerticalLine";
import { format, parse } from "date-fns";
import { useTranslation } from "../../../lang";
import { useLocation } from "react-router-dom";
import { GetDateFormat, GetDateOnlyFormat } from "../../../utils/generalUtils";

// TODO: remove index key from here.
//Contenedor de Steps
const StepsList = ({
  dayStep,
  classStep,
  selectStep,
  indexDayStep,
  markerJourney = {},
  toggleFillMarkerJourney2,
  toggleFillGenericPopup,
}) => {
  const [t] = useTranslation();
  const location = useLocation();
  const stepsList = (
    <div>
      {markerJourney.daySteps[indexDayStep].steps.map((step, indexStep) => {
        const fechaInicio = new Date(step.iTime);
        const horaInicioFormateada = format(
          new Date(fechaInicio).getTime(),
          "HH:mm:ss"
        );
        const fechaFin = new Date(step.fTime);
        const fechaHoraFinFormateada = format(
          new Date(fechaFin).getTime(),
          GetDateFormat(t("userLanguage"))
        );
        const horaFinFormateada = format(
          new Date(fechaFin).getTime(),
          "HH:mm:ss"
        );
        const fechaFinFormateada = format(
          new Date(fechaFin),
          GetDateFormat(t("userLanguage"), true)
        );
        const fechaInicioFormateada = format(
          new Date(fechaInicio),
          GetDateFormat(t("userLanguage"), true)
        );
        const fechaDayStep = new Date(parse(dayStep, "yyyy-MM-dd", new Date()));
        const dayStepFormateada = format(
          new Date(fechaDayStep),
          GetDateFormat(t("userLanguage"), true)
        );
        return (
          <div
            key={indexStep}
            onClick={() => {
              if (location.pathname === "/map") {
                selectStep({
                  indexStep,
                  indexDayStep,
                  markerJourney,
                  toggleFillMarkerJourney2,
                });
                toggleFillGenericPopup({ popupInfo: {} });
              }
            }}
            className={classStep({ indexStep, indexDayStep })}
          >
            <FlexRow bottomDivider expansion="full" _classNames="pad-2">
              <FlexRow direction="column">
                <div className="padding-bottom-steps">
                  <div>{horaInicioFormateada}</div>
                  {t("Inicio")}
                </div>
                <div>
                  <div>
                    {
                      //dayStepFormateada === fechaFinFormateada
                      fechaInicioFormateada === fechaFinFormateada
                        ? horaFinFormateada
                        : fechaHoraFinFormateada
                    }
                  </div>
                  {t("Parada")}
                </div>
              </FlexRow>
              <FlexRow _classNames="division-journeys-steps">
                <DivisionJourneys />
              </FlexRow>
              <FlexRow
                direction="column"
                expansion="full"
                _classNames="pad-hor-2 title-journeys-steps"
              >
                <div>{t("Duración del tramo")}</div>
                <div>{t("Distancia recorrida")}</div>
                <div className="datos-journeys-padding-steps">
                  {t("Duración de la parada")}
                </div>
              </FlexRow>

              <FlexRow
                direction="column"
                expansion="full"
                _classNames="pad-hor-1 datos-journeys-steps"
              >
                <div>{step.tIgn}</div>
                <div>{step.odo}</div>
                <div className="datos-journeys-padding-steps">{step.tDet}</div>
              </FlexRow>
              <FlexRow _classNames="vertical-line-journeys-steps">
                <VerticalLine />
              </FlexRow>
              <FlexRow direction="column" _classNames="pad-hor-1">
                <IconAccount color="icon-color-green"></IconAccount>
              </FlexRow>
            </FlexRow>
          </div>
        );
      })}
    </div>
  );
  return stepsList;
};

StepsList.propTypes = {
  classStep: PropTypes.func,
  selectStep: PropTypes.func,
  selectDaySteps: PropTypes.func,
  markerJourney: PropTypes.object,
  toggleFillMarkerJourney2: PropTypes.func,
  toggleFillGenericPopup: PropTypes.func,
};

StepsList.defaultProps = {
  classStep: () => {},
  selectStep: () => {},
  selectDaySteps: () => {},
  markerJourney: {},
  toggleFillMarkerJourney2: () => {},
  toggleFillGenericPopup: () => {},
};

export default StepsList;
