import React from "react";
import BusIcon from "../Map/AssetsIcons/BusIcon";
import CusterIcon from "../Map/AssetsIcons/CusterIcon";
import Icon4x4 from "../Map/AssetsIcons/Icon4x4";
import ManIcon from "../Map/AssetsIcons/ManIcon";
import MinivanIcon from "../Map/AssetsIcons/MinivanIcon";
import MotoIcon from "../Map/AssetsIcons/MotoIcon";
import SedanIcon from "../Map/AssetsIcons/SedanIcon";
import SportIcon from "../Map/AssetsIcons/SportIcon";
import StationIcon from "../Map/AssetsIcons/StationIcon";
import TractorIcon from "../Map/AssetsIcons/TractorIcon";
import TruckIcon from "../Map/AssetsIcons/TruckIcon";
import VanIcon from "../Map/AssetsIcons/VanIcon";

const AssetIcon = ({ typeMobile }) => {
  // MINIVAN    = 1
  // TRUCK      = 2
  // MAN        = 3
  // TRACTOR    = 4
  // SPORT      = 5
  // CUSTER     = 6
  // BUS        = 7
  // STATION    = 8
  // VAN        = 9
  // SEDAN      = 10
  // 4x4        = 11
  // MOTO       = 12

  switch (typeMobile) {
    case 1:
      return <MinivanIcon />;
    case 2:
      return <TruckIcon />;
    case 3:
      return <ManIcon />;
    case 4:
      return <TractorIcon />;
    case 5:
      return <SportIcon />;
    case 6:
      return <CusterIcon />;
    case 7:
      return <BusIcon />;
    case 8:
      return <StationIcon />;
    case 9:
      return <VanIcon />;
    case 10:
      return <SedanIcon />;
    case 11:
      return <Icon4x4 />;
    case 12:
      return <MotoIcon />;
    default:
      return <BusIcon />;
  }
};

export default AssetIcon;