import { Grid } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Select from "react-select";
import { useTranslation } from "../../lang";
import CustomSelectStyle from "../../utils/reactSelectStyle";
// const { SearchBar } = Search;
import CssTextField from "./CssTextField";
import "./RemoteTable.scss";

const theme = createMuiTheme({});

const paginationSizes = [10, 25, 50];

const Table = ({
  columns,
  data,
  keyField,
  selectRowOptions,
  paginationOptions,
  lateralComponent,
  afterSearch,
  rowEvents,
  rowStyle,
  headerClasses,
  bodyClasses,
  hideSearch,
  tableNumber,
}) => {
  const [t] = useTranslation();
  const [currentPage, setCurrentPage] = useState({
    label: paginationSizes[0].toString(),
    value: paginationSizes[0],
  });

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "black";
      activeStyle.color = "white";
    } else {
      activeStyle.backgroundColor = "gray";
      activeStyle.color = "black";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    return (
      <li className="page-item">
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  const paginationTotalRenderer = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("base_table_mostrar_resultados", { from, to, size })}
    </span>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    return (
      <div style={{ paddingTop: "7px" }}>
        {pages.map((p, index) => (
          <button
            key={index}
            className={`btnCustomPagination ${
              p.active && "btnCustomPaginationActive"
            }`}
            onClick={() => onPageChange(p.page)}
          >
            {p.page}
          </button>
        ))}
      </div>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => {
    return (
      <div className="pageSizeContainerSelector">
        <Select
          value={currentPage}
          options={options.map((opt) => ({ label: opt.text, value: opt.page }))}
          onChange={(opt) => {
            setCurrentPage(opt);
            onSizePerPageChange(opt.value);
          }}
          styles={CustomSelectStyle}
        />
      </div>
    );
  };

  const optionsPagination = {
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    sizePerPageList: paginationSizes,
    pageListRenderer,
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer,
  };

  const highlightText = (value, mark, tableNumber) => {
    const tableBody = document.getElementsByClassName("remoteTableBody")[
      tableNumber || 0
    ];
    if (!tableBody) return;

    const tds = tableBody.getElementsByTagName("td");
    for (let td of tds) {
      const text = td.innerText;
      if (
        text &&
        value &&
        text.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ) {
        const newText = text.replace(
          new RegExp(value, "gi"),
          (match) => `<mark>${match}</mark>`
        );

        td.innerHTML = newText;
      }

      if (!value && text) {
        td.innerHTML = text;
      }
    }
  };

  const MySearch = (props) => {
    return (
      <div>
        <CssTextField
          placeholder={t("Buscar")}
          style={{ marginBottom: "15px" }}
          variant="standard"
          onKeyUp={(evt) => {
            const text = evt.target.value;
            if (text.length >= 3) {
              props.onSearch(text);
              highlightText(text, true, props.tableNumber);
            }

            if (!text) {
              props.onSearch(text);
              highlightText(text, false, props.tableNumber);
            }
          }}
        />
      </div>
    );
  };

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data || []}
      columns={columns}
      search
    >
      {(props) => {
        return (
          <div>
            <Grid container>
              {/* {!hideSearch && ( */}
              <>
                <Grid item lg={6}>
                  {!hideSearch && (
                    <ThemeProvider theme={theme}>
                      <MySearch
                        {...props.searchProps}
                        tableNumber={tableNumber}
                      />
                    </ThemeProvider>
                  )}
                </Grid>
                <Grid item lg={6} style={{ paddingBottom: "4px" }}>
                  <>{lateralComponent}</>
                </Grid>
                <hr />
              </>
              {/* )} */}
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                //headerClasses="thead-light"
                pagination={paginationFactory(optionsPagination)}
                headerClasses={headerClasses || "remoteTableHeader"}
                bodyClasses={bodyClasses || "remoteTableBody"}
                selectRow={selectRowOptions}
                rowEvents={rowEvents}
                rowStyle={rowStyle}
                //filter={filterFactory()}
              />
            </Grid>
          </div>
        );
      }}
    </ToolkitProvider>
  );
};

export default Table;
