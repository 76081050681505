import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../lang";
import "./Login.scss";
import LoginForm from "./LoginForm";

const Login = ({
  // content,
  // date,
  // profilePicture,
  // userName,
  handleLoginSubmit,
}) => {
  const [t] = useTranslation();
  const pageTitle = process.env.REACT_APP_PAGE_TITLE;

  return (
    <Card className="login-container">
      <div className="login-container__item pad-bottom-3">
        <div id="login-brand-logo">
          <img src="/assets/img/logo.svg" alt={t(pageTitle)} />
        </div>
      </div>
      <LoginForm handleLoginSubmit={handleLoginSubmit} />

      <Typography component="span">
        <Link className="login-container__link" to="/">
          {" "}
          {/* ¿Olvidó su contraseña? */}
        </Link>
      </Typography>
      <Typography component="span">
        <Link className="login-container__link" to="/">
          {" "}
        </Link>
      </Typography>
    </Card>
  );
};

Login.propTypes = {
  handleLoginSubmit: PropTypes.func,
};

Login.defaultProps = {
  handleLoginSubmit: () => {},
};
export default Login;
