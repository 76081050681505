import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "../../lang";
import AssetState from "./AssetState";

const FlotaItem = ({
  point,
  openPanelAux,
  changePanelAuxType,
  fillMarkerInfo,
  style,
  setMarkerSelected,
}) => {
  const [t] = useTranslation();

  const handleOnClick = (point) => {
    changePanelAuxType("GeneralInfo");
    openPanelAux();
    //onChangeTab(2);
    fillMarkerInfo(point);
    setMarkerSelected(point.mid);
  };

  return (
    <Grid
      container
      className={`flotaItemContainer ${point.selected && "flotaItemSelected"}`}
      style={style}
      onClick={() => handleOnClick(point)}
    >
      <Grid item xs={1}>
        <AssetState point={point} />
      </Grid>
      <Grid item xs={8} className="flotaData">
        <label className="lblPointData">
          {/* {point.pat.length > 10 ? point.pat.substr(0, 10) + "..." : point.pat} */}
          {`${point.nMov} - ${point.pat}`}
        </label>
        <label className="lblPointData" title={point.dir}>
          {point.dir.length > 70 ? point.dir.substr(0, 70) + "..." : point.dir}
        </label>
      </Grid>
      <Grid
        item
        xs={3}
        className="flotaData"
        style={{ borderLeft: "1px solid #707070" }}
      >
        <label className="lblPointData">
          {t("components_estadoflotapanel_floatitem_point_vel", {
            pointVel: Math.round(point.vel),
          })}
        </label>
      </Grid>
    </Grid>
  );
};

export default FlotaItem;
