import { useLazyQuery, useQuery } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../../../components/Base/PageTitle";
import Structure from "../../../components/Base/Structure";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import DRIVERS_PAGINATED_QUERY from "../../../graphql/queries/getDriversPaginatedQuery";
import VELOCITY_REPORT_QUERY from "../../../graphql/queries/getVelocityReport";
import VELOCITY_REPORTEXCEL_QUERY from "../../../graphql/queries/getVelocityReportExcelQuery";
import VELOCITY_REPORTEKML_QUERY from "../../../graphql/queries/getVelocityReportKMLQuery";
import VELOCITY_REPORTEPDF_QUERY from "../../../graphql/queries/getVelocityReportPDFQuery";
import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";
import { useFilterValidation } from "../useFilterValidation";
import Filters from "./Filters";
import ReportResult from "./ReportResult";
import "./styles.scss";

//const paginationSizes = [10, 25, 50];

const VelocityReport = ({ selectedGroups, user }) => {
  const [t] = useTranslation();
  const [assetTableState] = useState({
    //pageNumber: 1,
    //pageSize: paginationSizes[0],
    searchText: "",
    sort: undefined,
    paginate: false,
  });
  const [assetList, setAssetList] = useState([]);
  const [driverList, setDriverList] = useState([]);

  const [reportResult, setReportResult] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getExcel] = useLazyQuery(VELOCITY_REPORTEXCEL_QUERY, {
    fetchPolicy: "network-only",

    onCompleted(d) {
      const fileType =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteVelocidad")}.xlsx`;
      downloadLink.href = `${fileType}${d.velocityReportExcel}`;
      downloadLink.click();
    },
  });

  const [getPDF] = useLazyQuery(VELOCITY_REPORTEPDF_QUERY, {
    fetchPolicy: "network-only",

    onCompleted(d) {
      const fileType = "data:application/pdf;base64,";
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteVelocidad")}.pdf`;
      downloadLink.href = `${fileType}${d.velocityReportPDF}`;
      downloadLink.click();
    },
  });

  const [getKML] = useLazyQuery(VELOCITY_REPORTEKML_QUERY, {
    fetchPolicy: "network-only",

    onCompleted(d) {
      const fileType = "data:application/vnd.google-earth.kml+xml,";
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteVelocidad")}.kml`;
      downloadLink.href = `${fileType}${d.velocityReportKML}`;
      downloadLink.click();
    },
  });

  const handleExportExcel = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    velocidadDesde,
    velocidadHasta,
    dias
  ) => {
    getExcel({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        velocidadDesde: velocidadDesde,
        velocidadHasta: velocidadHasta,
        days: dias,
      },
    });
  };

  const handleExportPDF = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    velocidadDesde,
    velocidadHasta,
    dias
  ) => {
    getPDF({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        velocidadDesde: velocidadDesde,
        velocidadHasta: velocidadHasta,
        days: dias,
      },
    });
  };

  const handleExportKML = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    velocidadDesde,
    velocidadHasta,
    dias
  ) => {
    getKML({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        velocidadDesde: velocidadDesde,
        velocidadHasta: velocidadHasta,
        days: dias,
      },
    });
  };

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(items.map((a) => ({
        ...a,
        completeName: `${a.name} - ${a.licensePlate}`
      })));
    },
  });

  const {} = useQuery(DRIVERS_PAGINATED_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      paginate: false,
    },
    onCompleted(d) {
      setDriverList(d.driversPaginated.items);
    },
  });

  const [fetchReport] = useLazyQuery(VELOCITY_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.velocityReport && d.velocityReport.length > 0)
        setReportResult(d.velocityReport);
      else {
        setReportResult(null);
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleSearchReportVelocity = ({
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    velocidadDesde,
    velocidadHasta,
    dias,
  }) => {
    fetchReport({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        velocidadDesde: velocidadDesde,
        velocidadHasta: velocidadHasta,
        days: dias,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportVelocity
  );

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Velocidad")} />
      <Filters
        assets={assetList}
        drivers={driverList}
        searchReport={handleSearchReport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        handleExportKML={handleExportKML}
        errors={filterErrors}
        reportResult={Array.isArray(reportResult) && reportResult.length > 0}
        user={user}
      />
      {Array.isArray(reportResult) && reportResult.length > 0 && (
        <ReportResult reportData={reportResult} />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    user: user,
  };
};

export default connect(_mapStateToProps, null)(VelocityReport);
