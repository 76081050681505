import React, { useState } from "react";

import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import { downloadExcel } from "../../../utils/generalUtils";

import Structure from "../../../components/Base/Structure";

import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import CONSOLIDATE_REPORT_QUERY from "../../../graphql/queries/consolidateReportQuery";

import Filter from "./Filter";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PageTitle from "../../../components/Base/PageTitle";
import { useFilterValidation } from "../useFilterValidation";

import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";

const ConsolidateReport = ({ selectedGroups }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(items.map((a) => ({
        ...a,
        completeName: `${a.name} - ${a.licensePlate}`
      })));
    },
  });

  const [fetchReport] = useLazyQuery(CONSOLIDATE_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteConsolidado");
      if (d.consolidatedReportExportExcel)
        downloadExcel(d.consolidatedReportExportExcel, filename);
      else
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleSearchReportConsolidate = ({
    assetsSelected,
    fechaDesde,
    fechaHasta,
    dias,
    resumed,
    useIbutton
  }) => {
    console.log("useibutton: ", useIbutton);
    fetchReport({
      variables: {
        assetsSelected: assetsSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        days: dias,
        resumed: resumed,
        useIbutton: useIbutton
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportConsolidate
  );

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Consolidados")} />
      <Filter
        assets={assetList}
        searchReport={handleSearchReport}
        errors={filterErrors}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    selectedGroups: groups.selectedGroups,
  };
};

export default connect(_mapStateToProps, null)(ConsolidateReport);
