import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { render } from "react-dom";
import { isEmpty } from "./utils/lodashReplace";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import fakeContext from "./__fixtures__/fakeContext.json";

// Get the data from the backend if existent
let props = window.__SERVER_DATA__ || {};

// While on dev, lets use some mock data
if (process.env.NODE_ENV === "development" && isEmpty(props)) {
  props = fakeContext;
}

render(
  // <ApolloProvider client={client}>
  <App {...props} />,
  // </ApolloProvider>,
  document.getElementById("root")
);
